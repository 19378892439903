import { AsyncResult, IActionContext } from '@msdyn365-commerce/core';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { SimpleProductClass } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { Recipe, RecipeBuilder, RecipeConverter } from '../DataModel/Recipe';
import { Deal, DealConverter } from '../DataModel/Deals/Deal';
import {
    createLsCartLineFromProductAsync,
    saveLsCartLinesAsync
} from '../../../../restaurants.commerce/Platform/RetailProxy/DataActionExtension.g';
import { ILsCart, ILsCartLine } from '../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';

export class LsCartLinesHandler {
    /**
     * Gets a Recipe or Deal from a given SimpleProduct.
     *
     * @param product SimpleProductClass. The SimpleProduct to convert to a Recipe or Deal.
     * @param context IActionContext. Context from the Buybox.
     * @returns Promise<Recipe | Deal | SimpleProductClass>. Return type will depend on the data that is returned from the CSU.
     */
    public async GetRecipeOrDealFromProduct(
        product: SimpleProductClass,
        context: IActionContext
    ): Promise<Recipe | Deal | SimpleProductClass> {
        try {
            const recipeOrDealDetails = await createLsCartLineFromProductAsync({ callerContext: context }, product.ItemId);
            if (ObjectExtensions.isNullOrUndefined(recipeOrDealDetails)) {
                return product;
            }
            if (ObjectExtensions.isNullOrUndefined(recipeOrDealDetails.DealLines) || recipeOrDealDetails.DealLines.length === 0) {
                return new RecipeConverter().FromLsCartLineAndProduct(new RecipeBuilder(Recipe), recipeOrDealDetails, product);
            } else {
                return new DealConverter().FromLsCartLineAndProduct(recipeOrDealDetails, product);
            }
        } catch (e) {
            console.log('Promise rejected');
            console.error(e);
            return new AsyncResult<Recipe>(function(resolve, reject) {
                reject(1);
            });
        }
    }

    /**
     * Saves the modifications to a Recipe or Deal, stored as a LsCartLine.
     *
     * @remarks
     *      For eCommerce we will only be adding one CartLine at a time, at least at the time of this writing, the CartLine
     * will also be a brand new CartLine that does not already exist in the Cart, as there is currently no edit CartLine
     * mechanism. With that said the CRT will either add or update an existing CartLine if the CartLine is already present
     * in the Cart, so if we do add an update mechanism the CRT will not have to be updated at all.
     *
     * @param cartId String. CartId for the Cart that the LsCartLine belongs to.
     * @param cartLine ILsCartLine. Recipe or Deal converted in to a LsCartLine.
     * @param context IActionContext. Context from the Buybox.
     * @returns Promise<ILsCart>. This LsCart after it has been updated.
     */
    public async SaveRecipeOrDealModifications(cartId: string, cartLine: ILsCartLine, context: IActionContext): Promise<ILsCart> {
        try {
            const cartDetails = await saveLsCartLinesAsync({ callerContext: context }, cartId, [cartLine]);
            return cartDetails;
        } catch (e) {
            console.log('Promise rejected');
            console.error(e);
            return new AsyncResult<ILsCart>(function(resolve, reject) {
                reject(1);
            });
        }
    }
}
