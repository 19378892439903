import { Cart, CartLine } from '@msdyn365-commerce/retail-proxy';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { SimpleProductClass } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { ILsError } from '../../../../modules/lsfirst-buybox/State/LsFirstBuyboxState';

import {
    ILsCartLine,
    ILsComments,
    ILshIngredient,
    ILsPredefinedCommentGroup,
    ILshProductModifierGroup
} from '../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { LsCartLineBuilder } from '../../Carts/CartLines/DataModel/LsCartLine';
import { SimpleProductClassBuilder } from './SimpleProductClassBuilder';

interface IMapOfGroupCommentsValue {
    SelectedComments: string[];
    MinSelection: number;
    MaxSelection: number;
    CommentsMap: {
        [key: string]: 0;
    };
}

interface IMapOfGroupComments {
    [key: string]: IMapOfGroupCommentsValue;
}

export class Recipe extends SimpleProductClass {
    public Ingredients?: ILshIngredient[];
    public PredefinedComments?: ILsPredefinedCommentGroup[];
    public ProductModifierGroups?: ILshProductModifierGroup[];
    public SelectedComments?: ILsComments;

    public static IsRecipe(object: object): boolean {
        if (object instanceof Recipe) {
            // check if it is not a DealLine (since DealLine is instance of Recipe now)
            if ('DealModifierGroups' in object) {
                return false;
            }
            return true;
        } else if (object instanceof Object) {
            // How to check if give object has expected properties: https://dmitripavlutin.com/check-if-object-has-property-javascript/
            const isRecipe: boolean =
                'Ingredients' in object &&
                'PredefinedComments' in object &&
                'ProductModifierGroups' in object &&
                'SelectedComments' in object &&
                // check if it is not a Deal or a DealLine
                !('DealModifierGroups' in object) &&
                !('DealLines' in object);
            return isRecipe;
        }
        return false;
    }

    public static ValidateIngredients(recipe: Recipe): ILsError[] {
        const ingredients = recipe.Ingredients;
        const errors: ILsError[] = [];
        if (!ingredients) {
            return errors;
        }
        // currently there is no extra validation required for ingredients
        return errors;
    }

    public static ValidatePredefinedComments(recipe: Recipe): ILsError[] {
        const predefinedCommentGroups = recipe.PredefinedComments;
        const selectedComments = recipe.SelectedComments?.CommentStrings ?? [];
        const errors: ILsError[] = [];
        if (!predefinedCommentGroups) {
            return errors;
        }

        // what the following code generates:
        /*
        mapOfGroupComments = {
            "BurgerComments": {
                selectedComments: [here will be the selected comments that belong to the group of id "BurgerComments"],
                MinSelection: 0,
                MaxSelection: 0,
                commentsMap: {
                    'Crispy': 0, // 0 has no meaning, all we want to do is use the key as a quick check to see if a comment belongs to the group
                    'Blackened': 0,
                    'Spicy': 0
                }
            }
        }
        */
        const mapOfGroupComments: IMapOfGroupComments = predefinedCommentGroups.reduce((currentMapOfGroupComments, group) => {
            if (ObjectExtensions.isNullOrUndefined(group.GroupId) || ObjectExtensions.isNullOrUndefined(group.PredefinedComments)) {
                return currentMapOfGroupComments;
            }
            currentMapOfGroupComments[group.GroupId] = {
                SelectedComments: [],
                MaxSelection: group.MaxSelection,
                MinSelection: group.MinSelection,
                CommentsMap: group.PredefinedComments.reduce((_map, commentObj) => {
                    if (ObjectExtensions.isNullOrUndefined(commentObj.Comment)) {
                        return _map;
                    }
                    _map[commentObj.Comment] = 0;
                    return _map;
                }, {})
            };
            return currentMapOfGroupComments;
        }, {});

        // for each group, add to it the corresponding selected comments then run validation
        for (const groupid in mapOfGroupComments) {
            const group = mapOfGroupComments[groupid];
            group.SelectedComments = selectedComments.filter(comment => !ObjectExtensions.isNullOrUndefined(group.CommentsMap[comment]));
            if (group.MaxSelection > 0 && group.MaxSelection < group.SelectedComments.length) {
                errors.push({
                    errorLocation: 'PredefinedComments',
                    errorMessage: `Please select at most ${group.MaxSelection} option(s)`,
                    errorMessageCaption: recipe.Name ? `${recipe.Name} option(s)` : '',
                    predefinedCommentsGroupId: groupid
                });
            }

            if (group.MinSelection > group.SelectedComments.length) {
                errors.push({
                    errorLocation: 'PredefinedComments',
                    errorMessage: `Please select at least ${group.MinSelection} option(s)`,
                    errorMessageCaption: recipe.Name ? `${recipe.Name} option(s)` : '',
                    predefinedCommentsGroupId: groupid
                });
            }
        }
        return errors;
    }

    public static ValidateSelectedProductModifiers(recipe: Recipe, dealLineId?: number): ILsError[] {
        const productModifierGroups = recipe.ProductModifierGroups;
        if (!productModifierGroups) {
            return [];
        }

        const errors: ILsError[] = [];

        for (const productModifierGroup of productModifierGroups) {
            if (!productModifierGroup.ModifierGroupId) {
                throw new Error('[ValidateSelectedProductModifiers] ProductModifierGroup lacks an ID');
            }
            const modifiers = productModifierGroup.Modifiers ?? [];
            const totalQuantity = modifiers.reduce((currentTotal, modifier) => currentTotal + modifier.Quantity, 0);

            if (totalQuantity < productModifierGroup.MinQuantity) {
                errors.push({
                    errorLocation: 'ProductModiferGroups',
                    errorMessage: `Please select at least ${productModifierGroup.MinQuantity} item`,
                    modifierGroupId: productModifierGroup.ModifierGroupId,
                    errorMessageCaption: productModifierGroup.Caption,
                    dealLineId: dealLineId
                });
            }
        }
        return errors;
    }
}

export class RecipeBuilder<Type extends Recipe> extends SimpleProductClassBuilder<Type> {
    public Copy(recipe: Type): RecipeBuilder<Type> {
        super.Copy(recipe);

        if (!ObjectExtensions.isNullOrUndefined(recipe.Ingredients)) {
            this.Ingredients(recipe.Ingredients);
        }

        if (!ObjectExtensions.isNullOrUndefined(recipe.PredefinedComments)) {
            this.PredefinedComments(recipe.PredefinedComments);
        }

        if (!ObjectExtensions.isNullOrUndefined(recipe.ProductModifierGroups)) {
            this.ProductModifierGroups(recipe.ProductModifierGroups);
        }

        if (!ObjectExtensions.isNullOrUndefined(recipe.SelectedComments)) {
            this.SelectedComments(recipe.SelectedComments);
        }

        return this;
    }

    public Ingredients(value: ILshIngredient[]): RecipeBuilder<Type> {
        this.object.Ingredients = value;
        return this;
    }

    public PredefinedComments(value: ILsPredefinedCommentGroup[]): RecipeBuilder<Type> {
        this.object.PredefinedComments = value;
        return this;
    }

    public ProductModifierGroups(value: ILshProductModifierGroup[]): RecipeBuilder<Type> {
        this.object.ProductModifierGroups = value;
        return this;
    }

    public SelectedComments(value: ILsComments): RecipeBuilder<Type> {
        this.object.SelectedComments = value;
        return this;
    }
}

export class RecipeConverter {
    public FromLsCartLineAndProduct(recipeBuilder: RecipeBuilder<Recipe>, lsCartLine: ILsCartLine, product: SimpleProductClass): Recipe {
        recipeBuilder.Copy(product);

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.Ingredients)) {
            recipeBuilder.Ingredients(lsCartLine.Ingredients);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.ProductModifierGroups)) {
            recipeBuilder.ProductModifierGroups(lsCartLine.ProductModifierGroups);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.PredefinedComments)) {
            recipeBuilder.PredefinedComments(lsCartLine.PredefinedComments);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.Comments)) {
            recipeBuilder.SelectedComments(lsCartLine.Comments);
        }

        return recipeBuilder.Build();
    }

    public ToLsCartLine(cart: Cart, cartLine: CartLine, recipe: Recipe): ILsCartLine {
        const lsCartLineBuilder = new LsCartLineBuilder()
            .CartId(cart.Id)
            .IsPriceKeyedIn(cartLine.IsPriceKeyedIn ?? false)
            .IsPriceOverridden(cartLine.IsPriceOverridden ?? false)
            .IsVoided(cartLine.IsVoided ?? false)
            .LineNumber(cartLine.LineNumber ?? 0)
            //.LshCartLineGroupId() // Currently not using this in eCommerce
            .Price(cartLine.Price ?? 0)
            .ProductId(cartLine.ProductId ?? 0)
            .Quantity(cartLine.Quantity ?? 0)
            .SalesDate(cart.BusinessDate ?? new Date());
        //.UnitOfMeasureDecimalPrecision() // Currently not using this in eCommerce

        if (ObjectExtensions.isNullOrUndefined(cartLine.OriginalPrice)) {
            lsCartLineBuilder.OriginalPrice(cartLine.Price ?? 0);
        } else {
            lsCartLineBuilder.OriginalPrice(cartLine.OriginalPrice);
        }

        if (!ObjectExtensions.isNullOrUndefined(cart.CustomerId)) {
            lsCartLineBuilder.CustomerId(cart.CustomerId);
        }

        if (!ObjectExtensions.isNullOrUndefined(recipe.Ingredients)) {
            lsCartLineBuilder.Ingredients(recipe.Ingredients);
        }

        if (!ObjectExtensions.isNullOrUndefined(cartLine.ItemId)) {
            lsCartLineBuilder.ItemId(cartLine.ItemId);
        }

        if (!ObjectExtensions.isNullOrUndefined(cartLine.LineId)) {
            lsCartLineBuilder.LineId(cartLine.LineId);
            lsCartLineBuilder.OriginalLineId(cartLine.LineId);
        }

        if (!ObjectExtensions.isNullOrUndefined(recipe.PredefinedComments)) {
            lsCartLineBuilder.PredefinedComments(recipe.PredefinedComments);
        }

        if (!ObjectExtensions.isNullOrUndefined(recipe.ProductModifierGroups)) {
            lsCartLineBuilder.ProductModifierGroups(recipe.ProductModifierGroups);
        }

        if (!ObjectExtensions.isNullOrUndefined(recipe.SelectedComments)) {
            lsCartLineBuilder.Comments(recipe.SelectedComments);
        }

        return lsCartLineBuilder.Build();
    }
}
