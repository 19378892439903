//import { ReactElement } from 'enzyme/node_modules/@types/react';
import * as React from 'react';
import { makeAlertFromLsErrorArray } from '../../../../modules/lsfirst-buybox/components/LsFirstAlert';
import { LsFirstBuyboxContext } from '../../../../modules/lsfirst-buybox/Context/LsFirstBuyboxContext';
import { ILsFirstBuyboxCallbacks } from '../../../../modules/lsfirst-buybox/ILsFirstBuyboxCallbacks';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { ILsPredefinedCommentGroup } from '../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { DealLine } from '../DataModel/Deals/DealLines/DealLine';
import { Recipe } from '../DataModel/Recipe';

export class PredefinedComments {
    public static GetPredefinedComments(
        recipeOrDealLine: Recipe | DealLine,
        callbacks: ILsFirstBuyboxCallbacks
    ): React.ReactNode | undefined {
        const predefinedComments = recipeOrDealLine.PredefinedComments || [];
        if (predefinedComments.length === 0) {
            return undefined;
        } else {
            var predefinedCommentsToElementConverter = new PredefinedComments();
            var predefinedCommentsAsElement = predefinedCommentsToElementConverter.CreateReactElement(
                predefinedComments,
                recipeOrDealLine,
                callbacks
            );

            return predefinedCommentsAsElement;
        }
    }

    private CreateReactElement(
        predefinedComments: ILsPredefinedCommentGroup[],
        recipeOrDealLine: Recipe | DealLine,
        callbacks: ILsFirstBuyboxCallbacks
    ): React.ReactElement {
        const predefinedCommentsAsElement = (
            <LsFirstBuyboxContext.Consumer>
                {({ lsErrorState }) => {
                    const thisProductErrors = lsErrorState ?? [];
                    return (
                        <div>
                            <h2>Options</h2>
                            <br></br>
                            {predefinedComments.map((predefinedCommentGroup, index) => {
                                const predefinedCommentGroupErrors = thisProductErrors.filter(
                                    lsError =>
                                        lsError.errorLocation === 'PredefinedComments' &&
                                        lsError.predefinedCommentsGroupId === predefinedCommentGroup.GroupId
                                );
                                // create hash map of Comments for this predefinedCommentGroup so it's easier to filter out
                                // comments not belonging to it
                                const commentsFromThisGroup = predefinedCommentGroup.PredefinedComments?.reduce(
                                    (map: { [key: string]: 0 }, currentComment) => {
                                        if (!currentComment.Comment) {
                                            return map;
                                        }
                                        map[currentComment.Comment] = 0;
                                        return map;
                                    },
                                    {}
                                );
                                const selectedCommentsFromThisGroup = recipeOrDealLine.SelectedComments?.CommentStrings?.filter(
                                    commentString => !ObjectExtensions.isNullOrUndefined(commentsFromThisGroup?.[commentString])
                                );
                                return (
                                    <div key={index}>
                                        <h5>{predefinedCommentGroup.Description}</h5>
                                        <br></br>
                                        {makeAlertFromLsErrorArray(
                                            predefinedCommentGroupErrors,
                                            'There are some issues here',
                                            'Errors for Predefined Comments'
                                        )}
                                        <div className='row PredefinedComments-checkboxes-container'>
                                            {predefinedCommentGroup.PredefinedComments?.map(predefinedComment => {
                                                if (ObjectExtensions.isNullOrUndefined(predefinedComment.Comment)) {
                                                    throw new Error(
                                                        '[CreateReactElement] Unexpected undefined at PredefinedComment.Comment'
                                                    );
                                                }
                                                return (
                                                    <label key={predefinedCommentGroup.ItemId + predefinedComment.Comment}>
                                                        <input
                                                            disabled={
                                                                !this.InputShouldBeEnabled(
                                                                    predefinedCommentGroup.MaxSelection,
                                                                    selectedCommentsFromThisGroup,
                                                                    // we null checked above
                                                                    predefinedComment.Comment
                                                                )
                                                            }
                                                            type='checkbox'
                                                            onChange={() =>
                                                                // we null checked above
                                                                {
                                                                    this.UpdateComments(
                                                                        predefinedComment.Comment!,
                                                                        recipeOrDealLine,
                                                                        callbacks
                                                                    );
                                                                    callbacks.removeErrorsOfCategory('PredefinedComments', {
                                                                        predefinedCommentsGroupId: predefinedCommentGroup.GroupId
                                                                    });
                                                                }
                                                            }
                                                        />
                                                        <span>{predefinedComment.Comment}</span>
                                                    </label>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                            <br></br>
                        </div>
                    );
                }}
            </LsFirstBuyboxContext.Consumer>
        );

        return predefinedCommentsAsElement;
    }

    private InputShouldBeEnabled(
        maxSelection: number,
        selectedCommentsFromThisGroup: string[] | undefined,
        currentComment: string
    ): boolean {
        if (maxSelection === 0) {
            return true;
        }

        if (ObjectExtensions.isNullOrUndefined(selectedCommentsFromThisGroup) || selectedCommentsFromThisGroup.length < maxSelection) {
            return true;
        }

        const commentIsSelected = ObjectExtensions.isNullOrUndefined(
            selectedCommentsFromThisGroup.find((commentString: any) => commentString === currentComment)
        )
            ? false
            : true;
        // if we're on the selected comment input, we have to leave it enabled so the user can deselect it
        if (commentIsSelected) {
            return true;
        }

        return false;
    }

    private UpdateComments(commentToUpdate: string, recipeOrDealLine: Recipe | DealLine, callbacks: ILsFirstBuyboxCallbacks): void {
        callbacks.updateComments(commentToUpdate, recipeOrDealLine);
    }
}
