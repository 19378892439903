import { FinitePromiseQueueError, IProductInventoryInformation, ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { ProductDeliveryOptions, ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { IBuyboxCallbacks, IErrorState } from '@msdyn365-commerce-modules/buybox';
import * as _ from 'lodash/fp';
import {
    ILshIngredient,
    ILshProductModifier,
    ILshProductModifierGroup
} from '../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { Recipe } from '../../../restaurants.online/Platform/Products/DataModel/Recipe';
import { ILsFirstBuyboxCallbacks } from '../ILsFirstBuyboxCallbacks';
import Buybox from '../lsfirst-buybox';
import { ILsfirstBuyboxResources } from '../lsfirst-buybox.props.autogenerated';
import { DealLine } from '../../../restaurants.online/Platform/Products/DataModel/Deals/DealLines/DealLine';
import { Deal } from '../../../restaurants.online/Platform/Products/DataModel/Deals/Deal';
import { DealModifier } from '../../../restaurants.online/Platform/Products/DataModel/Deals/DealModifierGroups/DealModifiers/DealModifier';
import { ILsError, ILshErrorLocation } from '../State/LsFirstBuyboxState';
import { ILsDealDefaultSelections } from '..';

export class LsFirstBuyboxCallbacks {
    private Buybox: Buybox;

    constructor(buybox: Buybox) {
        this.Buybox = buybox;
    }

    public readonly MicrosoftBuyboxCallbacks: IBuyboxCallbacks = {
        updateQuantity: (newQuantity: number): boolean => {
            const errorState = { ...this.Buybox.state.errorState };
            errorState.quantityError = undefined;
            errorState.otherError = undefined;

            this.Buybox.setState({ quantity: newQuantity, errorState });
            return true;
        },
        updateErrorState: (newErrorState: IErrorState): void => {
            this.Buybox.setState({ errorState: newErrorState });
        },
        updateSelectedProduct: (
            newSelectedProduct: Promise<SimpleProduct | null>,
            newInventory: IProductInventoryInformation | undefined,
            newPrice: ProductPrice | undefined,
            newDeliveryOptions: ProductDeliveryOptions | undefined
        ): void => {
            this.Buybox.setState({
                selectedProduct: newSelectedProduct,
                productAvailableQuantity: newInventory,
                productDeliveryOptions: newDeliveryOptions
            });
            this.Buybox._updatePrice(newPrice);
        },
        dimensionSelectedAsync: async (selectedDimensionId: number, selectedDimensionValueId: string): Promise<void> => {
            this.Buybox.dimensions[selectedDimensionId] = selectedDimensionValueId;
            return this.Buybox.dimensionUpdateQueue
                .enqueue(async () => {
                    return this.Buybox._updateDimensions();
                })
                .catch((error: any) => {
                    // Ignore discarded processes.
                    if (error !== FinitePromiseQueueError.ProcessWasDiscardedFromTheQueue) {
                        throw error;
                    }
                });
        },
        getDropdownName: (dimensionType: number, resources: ILsfirstBuyboxResources): string => {
            return this.Buybox._getDropdownName(dimensionType, resources);
        },
        changeModalOpen: (isModalOpen: boolean): void => {
            this.Buybox.setState({ modalOpen: isModalOpen });
        },
        changeUpdatingDimension: (isUpdatingDimension: boolean): void => {
            this.Buybox.setState({ isUpdatingDimension });
        },

        /**
         * Update isUpdatingDeliveryOptions state.
         *
         * @param isUpdatingDeliveryOptions - The status of updating delivery options.
         */
        changeUpdatingDeliveryOptions: (isUpdatingDeliveryOptions: boolean): void => {
            this.Buybox.setState({ isUpdatingDeliveryOptions });
        },

        updateKeyInPrice: (customPrice: number): void => {
            // Remove custom amount error when updating the custom price
            const errorState = { ...this.Buybox.state.errorState };
            errorState.customAmountError = undefined;

            this.Buybox.setState({ isPriceKeyedIn: true, keyInPriceAmount: customPrice, errorState });
            this.Buybox._updatePrice(this.Buybox.state.productPrice, customPrice);
        }
    };

    public readonly LsCallbacks: ILsFirstBuyboxCallbacks = {
        updateSelectedDealLineModifier: (key: string | undefined, value: string | undefined): void => {
            if (ObjectExtensions.isNullOrUndefined(key) || ObjectExtensions.isNullOrUndefined(value)) {
                throw new Error('[updateSelectedDealLineModifier]: key or value cannot be undefined');
            }

            // clone dealDefaultSelections state for updating. Spread operator is enough here since nothing is deeply nested
            const currentSelections: ILsDealDefaultSelections = { ..._.getOr({}, 'Buybox.state.dealDefaultSelections', this) };
            const deal = _.cloneDeep(this.Buybox.state.deal);

            // update selected deal modifier to the new one
            currentSelections[key] = value;

            if (ObjectExtensions.isNullOrUndefined(deal)) {
                throw new Error(`[updateSelectedDealLineModifier] Deal not found in state"`);
            }

            const dealLine: DealLine | undefined = deal.DealLines?.find(_dealLine => _dealLine.ItemId === key);
            if (!dealLine) {
                throw new Error(`[updateSelectedDealLineModifier] DealLine of ID "${value} not found in state"`);
            }

            // remove errors for deal line id
            this.LsCallbacks.removeErrorsForDealLine(dealLine.RecordId);

            // no deal line modifier selected
            // the DealLine ItemId is also used as the key, so if key === value
            // it means that the user is changing back to the default Deal and
            // removing the DealModifier
            if (key === value) {
                dealLine.DealModifierToSwapWith = undefined;
            } else {
                // get the DealModifier
                const dealModifier = DealModifier.GetDealModifierById(dealLine, value);
                if (!dealModifier) {
                    throw new Error(`[updateSelectedDealLineModifier] DealModifier of ID "${value} does not exist for this DealLine"`);
                }

                dealLine.DealModifierToSwapWith = dealModifier;
            }
            this.Buybox.setState({ dealDefaultSelections: currentSelections, deal: deal });
        },

        updateRecipeOrDealLineIngredientModifications: (ingredient: ILshIngredient, dealLineFromState?: DealLine): void => {
            if (!this.Buybox.state.recipe && !this.Buybox.state.deal) {
                return;
            }
            if (this.Buybox.state.recipe) {
                const temporaryRecipe = _.cloneDeep(this.Buybox.state.recipe);
                const temporaryRecipeIngredients = temporaryRecipe.Ingredients;
                if (ObjectExtensions.isNullOrUndefined(temporaryRecipeIngredients)) {
                    throw new Error('[updateRecipeOrDealLineIngredientModifications]Recipe should have an Ingredients array here');
                }
                const ingredientIndex = temporaryRecipeIngredients.findIndex(
                    ingredientInRecipe => ingredientInRecipe.ItemId === ingredient.ItemId
                );
                temporaryRecipeIngredients[ingredientIndex] = ingredient;
                this.Buybox.setState({ recipe: temporaryRecipe });
            }
            if (this.Buybox.state.deal && dealLineFromState) {
                const temporaryDeal = _.cloneDeep(this.Buybox.state.deal);
                const temporaryDealLines = temporaryDeal.DealLines;
                const dealDefaultSelections = this.Buybox.state.dealDefaultSelections;
                if (ObjectExtensions.isNullOrUndefined(dealDefaultSelections)) {
                    throw new Error(
                        '[updateRecipeOrDealLineIngredientModifications]dealDefaultSelections is undefined in the component state'
                    );
                }
                const temporaryDealDealLine = temporaryDealLines?.find(_dealLine => _dealLine.ItemId === dealLineFromState.ItemId);
                if (ObjectExtensions.isNullOrUndefined(temporaryDealDealLine)) {
                    return;
                }

                let ingredientsArrayToUpdate: ILshIngredient[] = [];
                const selectedDealModifierId = dealDefaultSelections[temporaryDealDealLine.ItemId];

                if (temporaryDealDealLine.ItemId === selectedDealModifierId) {
                    // no dealModifier selected, check top level Ingredients[]
                    ingredientsArrayToUpdate = temporaryDealDealLine.Ingredients ?? [];
                } else {
                    const selectedDealModifier = DealModifier.GetDealModifierById(temporaryDealDealLine, selectedDealModifierId);
                    if (ObjectExtensions.isNullOrUndefined(selectedDealModifier)) {
                        throw new Error(
                            '[updateRecipeOrDealLineIngredientModifications] The selected DealModifier does not exist or is not correctly formatted'
                        );
                    }
                    ingredientsArrayToUpdate = selectedDealModifier.Ingredients ?? [];
                }
                const ingredientIndex = ingredientsArrayToUpdate.findIndex(
                    ingredientInRecipe => ingredientInRecipe.ItemId === ingredient.ItemId
                );
                if (ingredientIndex === -1) {
                    return;
                }
                ingredientsArrayToUpdate[ingredientIndex] = ingredient;
                this.Buybox.setState({ deal: temporaryDeal });
            }
        },
        updateRecipeOrDealLineProductModifierModifications: (
            productModifier: ILshProductModifier,
            productModifierGroup: ILshProductModifierGroup,
            dealLineFromState?: DealLine
        ): void => {
            if (this.Buybox.state.recipe) {
                const _recipe: Recipe = _.cloneDeep(this.Buybox.state.recipe);
                const _productModifierGroups = _recipe.ProductModifierGroups ?? [];
                // find index of the correct subarray of product modifiers
                // then inside that array, find the index of the correct
                // product modifier
                const productModifierGroupIndex = _productModifierGroups.findIndex(
                    _productModifierGroup => _productModifierGroup.ModifierGroupId === productModifierGroup.ModifierGroupId
                );

                const productModifierIndex = productModifierGroup.Modifiers?.findIndex(
                    _productModifier => _productModifier.ItemId === productModifier.ItemId
                );

                if (ObjectExtensions.isNullOrUndefined(productModifierIndex) || productModifierGroupIndex < 0 || productModifierIndex < 0) {
                    throw new Error('[updateRecipeOrDealLineProductModifierModifications] Unexpected index');
                }

                //@ts-ignore - if productModifierGroupIndex and productModifierIndex exist and aren't -1
                // then _productModifierGroups[productModifierGroupIndex].Modifiers also exists
                _productModifierGroups[productModifierGroupIndex].Modifiers[productModifierIndex] = productModifier;
                this.Buybox.setState({ recipe: _recipe });
            }
            if (this.Buybox.state.deal && this.Buybox.state.dealDefaultSelections && dealLineFromState) {
                const _deal = _.cloneDeep(this.Buybox.state.deal);
                const _dealLine = _deal.DealLines?.find(_dealLine => _dealLine.ItemId === dealLineFromState.ItemId);
                if (!_dealLine) {
                    throw new Error(
                        `[updateRecipeOrDealLineProductModifierModifications] DealLine of id ${dealLineFromState.ItemId} not found`
                    );
                }
                let productModifierGroupsArrayToUpdate: ILshProductModifierGroup[] = [];
                const selectedDealModifierId = this.Buybox.state.dealDefaultSelections[_dealLine.ItemId];
                if (_dealLine.ItemId === selectedDealModifierId) {
                    // no dealModifier selected, check top level ProductModifierGroups[]
                    productModifierGroupsArrayToUpdate = _dealLine.ProductModifierGroups ?? [];
                } else {
                    const selectedDealModifier = DealModifier.GetDealModifierById(_dealLine, selectedDealModifierId);
                    if (ObjectExtensions.isNullOrUndefined(selectedDealModifier)) {
                        throw new Error('[updateRecipeOrDealLineProductModifierModifications] DealModifier is badly formatted');
                    }
                    productModifierGroupsArrayToUpdate = selectedDealModifier.ProductModifierGroups ?? [];
                }

                const productModifierGroupIndex = productModifierGroupsArrayToUpdate.findIndex(
                    productModifierInGroup => productModifierInGroup.ModifierGroupId === productModifierGroup.ModifierGroupId
                );

                if (productModifierGroupIndex < 0) {
                    throw new Error('[updateRecipeOrDealLineProductModifierModifications] Index not found');
                }

                const productModifierIndex = productModifierGroupsArrayToUpdate[productModifierGroupIndex].Modifiers?.findIndex(
                    productModifierInGroup => productModifierInGroup.ItemId === productModifier.ItemId
                );

                if (ObjectExtensions.isNullOrUndefined(productModifierIndex) || productModifierIndex < 0) {
                    throw new Error('[updateRecipeOrDealLineProductModifierModifications] Index not found');
                }
                // @ts-ignore - same as above, indexes exist and not -1, then .Modifiers also exist
                productModifierGroupsArrayToUpdate[productModifierGroupIndex].Modifiers[productModifierIndex] = productModifier;
                this.Buybox.setState({ deal: _deal });
            }
        },

        updateProductPriceDueToModifications: (value: number): void => {
            if (ObjectExtensions.isNullOrUndefined(this.Buybox.state.productPrice)) {
                throw new Error('[updateProductPriceDueToModifications] productPrice not found in state');
            }
            const temporaryProductPrice: ProductPrice = { ...this.Buybox.state.productPrice };
            if (temporaryProductPrice.CustomerContextualPrice) {
                temporaryProductPrice.CustomerContextualPrice += value;
            }
            this.Buybox._updatePrice(temporaryProductPrice);
        },
        /**
         * Adds or removes comment from the given Recipe or DealLine.
         *
         * @param commentToUpdate String. Comment to add if missing or remove if present.
         * @param recipeOrDealLineToUpdate Recipe | DealLine. The Recipe or DealLine to modify.
         */
        updateComments: (commentToUpdate: string, recipeOrDealLineToUpdate: Recipe | DealLine): void => {
            const temporaryRecipeOrDealLine: Recipe | DealLine = _.cloneDeep(recipeOrDealLineToUpdate);
            // Are there existing comments, if so check for add or remove, if not then just add the first one
            if (!ObjectExtensions.isNullOrUndefined(temporaryRecipeOrDealLine.SelectedComments)) {
                // This object can be undefined as well. So doing a similar check as above, but init is different if undefined.
                if (!ObjectExtensions.isNullOrUndefined(temporaryRecipeOrDealLine.SelectedComments.CommentStrings)) {
                    // If comments exists then we will remove it, if not we will add it
                    if (temporaryRecipeOrDealLine.SelectedComments.CommentStrings.includes(commentToUpdate)) {
                        temporaryRecipeOrDealLine.SelectedComments.CommentStrings = temporaryRecipeOrDealLine.SelectedComments.CommentStrings.filter(
                            comment => comment !== commentToUpdate
                        );
                    } else {
                        temporaryRecipeOrDealLine.SelectedComments.CommentStrings.push(commentToUpdate);
                    }
                } else {
                    temporaryRecipeOrDealLine.SelectedComments.CommentStrings = [commentToUpdate];
                }
            } else {
                temporaryRecipeOrDealLine.SelectedComments = {
                    CommentStrings: [commentToUpdate]
                };
            }

            if (Recipe.IsRecipe(temporaryRecipeOrDealLine)) {
                this.Buybox.setState({ recipe: temporaryRecipeOrDealLine });
            } else {
                if (ObjectExtensions.isNullOrUndefined(this.Buybox.state.deal)) {
                    throw new Error('[updateComments]Deal is required when updating its DealLine.');
                }
                if (ObjectExtensions.isNullOrUndefined(this.Buybox.state.deal)) {
                    throw new Error('[updateComments]Deal is not present in the component state.');
                }

                // Need to cast into a DealLine in order to access its properties

                const temporaryDealLine: DealLine = temporaryRecipeOrDealLine as DealLine;
                temporaryDealLine.Comments = temporaryDealLine.SelectedComments;
                // Get a copy of the Deal
                const temporaryDeal: Deal = _.cloneDeep(this.Buybox.state.deal);
                if (ObjectExtensions.isNullOrUndefined(temporaryDeal.DealLines)) {
                    throw new Error('[updateComments]DealLines is missing from Deal');
                }
                temporaryDeal.DealLines[temporaryDealLine.DealLineNumber] = temporaryDealLine;

                // Set the Deal stored in state
                this.Buybox.setState({ deal: temporaryDeal });
            }
        },
        updateLsErrorState: (lsErrors: ILsError[]): void => {
            this.Buybox.setState({ lsErrorState: lsErrors });
        },
        removeErrorsForDealLine: (dealLineId: number): void => {
            const errorsForDeal = _.cloneDeep(this.Buybox.state.lsErrorState ?? []);
            const errorsWithoutThisDealLine = errorsForDeal.filter((lserror: ILsError) => lserror.dealLineId !== dealLineId);
            this.LsCallbacks.updateLsErrorState(errorsWithoutThisDealLine);
        },
        removeErrorsOfCategory: (errorLocation: ILshErrorLocation, options): void => {
            const errorsInState = _.cloneDeep(this.Buybox.state.lsErrorState ?? []);
            let newErrors: ILsError[] = [];
            if (errorLocation === 'PredefinedComments') {
                newErrors = errorsInState.filter(error => error.predefinedCommentsGroupId !== options?.predefinedCommentsGroupId);
            }
            if (errorLocation === 'ProductModiferGroups') {
                newErrors = errorsInState.filter(error => error.errorLocation !== errorLocation);
            }
            this.LsCallbacks.updateLsErrorState(newErrors);
        }
    };
}
