import { Cart, CartLine } from '@msdyn365-commerce/retail-proxy';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { SimpleProductClass } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { ILsCartLine } from '../../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { LsCartLineBuilder } from '../../../Carts/CartLines/DataModel/LsCartLine';
import { SimpleProductClassBuilder } from '../SimpleProductClassBuilder';
import { DealLine, DealLineConverter } from './DealLines/DealLine';

export class Deal extends SimpleProductClass {
    public DealLines?: DealLine[];

    public static IsDeal(object: object): boolean {
        if (object instanceof Deal) {
            return true;
        } else if (object instanceof Object) {
            // How to check if give object has expected properties: https://dmitripavlutin.com/check-if-object-has-property-javascript/
            const isDeal: boolean = 'DealLines' in object;
            return isDeal;
        }

        return false;
    }
}

export class DealBuilder extends SimpleProductClassBuilder<Deal> {
    constructor() {
        super(Deal);
    }

    public Copy(deal: Deal): DealBuilder {
        super.Copy(deal);

        if (!ObjectExtensions.isNullOrUndefined(deal.DealLines)) {
            this.DealLines(deal.DealLines);
        }

        return this;
    }

    public DealLines(value: DealLine[]): DealBuilder {
        this.object.DealLines = value;
        return this;
    }
}

export class DealConverter {
    public FromLsCartLineAndProduct(lsCartline: ILsCartLine, product: SimpleProductClass): Deal {
        const dealBuilder = new DealBuilder().Copy(product);
        if (!ObjectExtensions.isNullOrUndefined(lsCartline.DealLines)) {
            const dealLines = lsCartline.DealLines.map((dealLine, index) => new DealLineConverter().FromILshDealLine(dealLine, index));
            dealBuilder.DealLines(dealLines);
        }
        return dealBuilder.Build();
    }

    public ToLsCartLine(cart: Cart, cartLine: CartLine, deal: Deal): ILsCartLine {
        const lsCartLineBuilder = new LsCartLineBuilder()
            .CartId(cart.Id)
            // .Comments() // Comments are on the DealLine
            .IsPriceKeyedIn(cartLine.IsPriceKeyedIn ?? false)
            .IsPriceOverridden(cartLine.IsPriceOverridden ?? false)
            .IsVoided(cartLine.IsVoided ?? false)
            .LineNumber(cartLine.LineNumber ?? 0)
            // .LshCartLineGroupId() // Currently not using this in eCommerce
            .Price(cartLine.Price ?? 0)
            .ProductId(cartLine.ProductId ?? 0)
            .Quantity(cartLine.Quantity ?? 0)
            .SalesDate(cart.BusinessDate ?? new Date());
        // .UnitOfMeasureDecimalPrecision() // Currently not using this in eCommerce

        if (ObjectExtensions.isNullOrUndefined(cartLine.OriginalPrice)) {
            lsCartLineBuilder.OriginalPrice(cartLine.Price ?? 0);
        } else {
            lsCartLineBuilder.OriginalPrice(cartLine.OriginalPrice);
        }

        if (!ObjectExtensions.isNullOrUndefined(cartLine.ItemId)) {
            lsCartLineBuilder.ItemId(cartLine.ItemId);
        }

        if (!ObjectExtensions.isNullOrUndefined(cartLine.LineId)) {
            lsCartLineBuilder.LineId(cartLine.LineId);
            lsCartLineBuilder.OriginalLineId(cartLine.LineId);
        }

        if (!ObjectExtensions.isNullOrUndefined(cart.CustomerId)) {
            lsCartLineBuilder.CustomerId(cart.CustomerId);
        }

        if (!ObjectExtensions.isNullOrUndefined(deal.DealLines)) {
            lsCartLineBuilder.DealLines(deal.DealLines);
        }

        return lsCartLineBuilder.Build();
    }
}
