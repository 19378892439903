/* eslint-disable no-duplicate-imports */
import * as React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core-internal';
import { ProductPrice, ReleasedProductType, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import {
    ArrayExtensions,
    DeliveryMode,
    FinitePromiseQueue,
    getDeliveryOptionsForSelectedVariant,
    GetDeliveryOptionsForSelectedVariantInput,
    getDimensionsForSelectedVariant,
    GetDimensionsForSelectedVariantInput,
    getInventoryLevelCodeFromDimensionValue,
    getPriceForSelectedVariant,
    getProductAvailabilitiesForSelectedVariant,
    getSelectedVariant,
    IDimensionsApp,
    IDimensionValueForSelectedVariant,
    InventoryLevelValues,
    IPromiseQueue,
    PriceForSelectedVariantInput,
    ProductAvailabilitiesForSelectedVariantInput,
    SelectedVariantInput,
    setDimensionValuesToQuery
} from '@msdyn365-commerce-modules/retail-actions';
import { getTelemetryObject, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import {
    getBuyboxAddToCart,
    getBuyBoxInventoryLabel,
    getBuyboxKeyInPrice,
    getBuyboxProductAddToOrderTemplate,
    getBuyboxProductAddToWishlist,
    getBuyboxProductConfigure,
    getBuyboxProductDescription,
    getBuyboxProductPrice,
    getBuyboxProductQuantity,
    getBuyboxProductRating,
    getBuyboxProductTitle,
    getBuyboxProductUnitOfMeasure,
    getBuyboxShopSimilarButton,
    getQuantityLimitsMessages,
    RetailDefaultOrderQuantityLimitsFeatureName
} from '@msdyn365-commerce-modules/buybox';
import { IBuyboxCommonData, IBuyboxExtentedProps, ShopSimiliarButtonType } from '@msdyn365-commerce-modules/buybox';

import { ILsfirstBuyboxData } from './lsfirst-buybox.data';
import { ILsfirstBuyboxProps, ILsfirstBuyboxResources } from './lsfirst-buybox.props.autogenerated';
import { getBuyboxFindInStore } from './components/lsfirst-buybox-find-in-store';
import { SimpleProductClass } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { Recipe } from '../../restaurants.online/Platform/Products/DataModel/Recipe';
import { Deal } from '../../restaurants.online/Platform/Products/DataModel/Deals/Deal';
import { Ingredients } from '../../restaurants.online/Platform/Products/Views/Ingredients';
import { ProductModifierGroups } from '../../restaurants.online/Platform/Products/Views/ProductModifierGroups';
import { PredefinedComments } from '../../restaurants.online/Platform/Products/Views/PredefinedComments';
import { LsCartLinesHandler } from '../../restaurants.online/Platform/Products/RetailProxyWrappers/LsCartLineHandler';
import { DealLines } from '../../restaurants.online/Platform/Products/Views/DealLines';
import { ILsBuyboxState, ILsErrorState } from './State/LsFirstBuyboxState';
import { ILsBuyboxViewProps } from './ViewProps/LsFirstBuyboxViewProps';
import { LsFirstBuyboxCallbacks } from './Callbacks/LsFirstBuyboxCallbacks';
import { LSFirstAlert, LSFirstAlertList, LSFirstAlertListItem } from './components/LsFirstAlert';

export interface ILsDealDefaultSelections {
    [key: string]: string;
}

/**
 * Buybox Module.
 */
class Buybox extends React.PureComponent<ILsfirstBuyboxProps<ILsfirstBuyboxData>, ILsBuyboxState> {
    /**
     * A queue of tasks of processing the changes in the dimensions.
     * Limit to two processes:
     * 1 - for the current process, which is under execution at the moment.
     * 2 - next process, which will process the latest version of data.
     * @remark Enqueueing new promises will discard the previous ones (except the one which is under processing).
     */
    public readonly dimensionUpdateQueue: IPromiseQueue<void> = new FinitePromiseQueue<void>(2);

    public dimensions: { [id: number]: string } = {};

    private readonly telemetryContent: ITelemetryContent;

    constructor(props: ILsfirstBuyboxProps<ILsfirstBuyboxData>, state: ILsBuyboxState) {
        super(props);
        this.state = {
            errorState: {
                configureErrors: {}
            },
            quantity: 1,
            min: undefined,
            max: undefined,
            selectedProduct: undefined,
            productPrice: undefined,
            productDeliveryOptions: undefined,

            modalOpen: false,
            isUpdatingDimension: false,
            isUpdatingDeliveryOptions: false,

            recipe: undefined,
            deal: undefined,
            dealDefaultSelections: undefined,
            lsErrorState: [],
            lsAPILoaded: false
        };
        this.telemetryContent = getTelemetryObject(props.context.request.telemetryPageName!, props.friendlyName, props.telemetry);
    }

    public async componentDidMount(): Promise<void> {
        const {
            data: {
                product: { result: product },
                productPrice
            }
        } = this.props;

        void productPrice.then(async result => {
            this._updatePrice(result);
        });

        if (product) {
            // Check if the product is service or not by product type
            if (product.ItemTypeValue === ReleasedProductType.Service) {
                this.setState({ isServiceItem: true });
            }
            await this._updateQuantitiesInState(product);

            await new LsCartLinesHandler()
                .GetRecipeOrDealFromProduct(product as SimpleProductClass, this.props.context.actionContext)
                .then((recipeOrDeal: Recipe | Deal) => {
                    if (Recipe.IsRecipe(recipeOrDeal)) {
                        this.setState({ recipe: recipeOrDeal });
                    } else if (Deal.IsDeal(recipeOrDeal)) {
                        this._updateDealLinesModifiersDefaultValues(recipeOrDeal);
                        this.setState({ deal: recipeOrDeal });
                    }
                })
                .then(() => {
                    this.setState({ lsAPILoaded: true });
                });
        }
    }

    private _updateDealLinesModifiersDefaultValues(deal: Deal) {
        // there will be radio buttons that need a state to work so
        // we set the state here, including the default checked radio
        const defaultSelections = {};
        deal.DealLines?.forEach(dealLine => {
            if (ObjectExtensions.isNullOrUndefined(dealLine.ItemId)) {
                return;
            }
            defaultSelections[dealLine.ItemId] = dealLine.ItemId;
        });
        this.setState({ dealDefaultSelections: defaultSelections });
    }

    public render(): JSX.Element | null {
        const {
            slots: { mediaGallery },
            data: {
                product: { result: product }
            },
            config: { className = '' }
        } = this.props;

        if (!product) {
            this.props.context.telemetry.error('Product content is empty, module wont render');
            return null;
        }

        return this.RenderView(product, mediaGallery, className);
    }

    private RenderView(product: SimpleProduct, mediaGallery: React.ReactNode[], className: string): React.ReactElement {
        const viewProps = this.GetViewProperties(product, mediaGallery, className);
        return this.props.renderView(viewProps) as React.ReactElement;
    }

    private GetViewProperties(product: SimpleProduct, mediaGallery: React.ReactNode[], className: string): ILsBuyboxViewProps {
        const props = this.props as IBuyboxExtentedProps<IBuyboxCommonData>;
        const { max, recipe, deal } = this.state;

        const defaultMinimumKeyInPrice = 10;
        const defaultMaximumKeyInPrice = 100;

        const context = this.props.context as ICoreContext<IDimensionsApp>;
        const inventoryLevel = context.app.config.inventoryLevel;
        const dimensionValuesWithInventory = ArrayExtensions.flatten(
            ArrayExtensions.validValues(this.props.data.productDimensions.result?.map(value => value.dimensionValuesWithInventory))
        );
        const hasAvailableProducts =
            !ArrayExtensions.hasElements(dimensionValuesWithInventory) ||
            dimensionValuesWithInventory.some(value => {
                const inventoryLevelCode = getInventoryLevelCodeFromDimensionValue(value, inventoryLevel);
                return inventoryLevelCode !== InventoryLevelValues.outOfStock;
            });

        const buyboxCallBacks = new LsFirstBuyboxCallbacks(this);

        const viewProps: ILsBuyboxViewProps = {
            ...(this.props as ILsfirstBuyboxProps<ILsfirstBuyboxData>),
            state: this.state,
            mediaGallery: mediaGallery && mediaGallery.length > 0 ? mediaGallery[0] : undefined,
            ModuleProps: {
                moduleProps: this.props,
                className: classnames('ms-buybox', className)
            },
            ProductInfoContainerProps: {
                className: 'ms-buybox__content'
            },
            MediaGalleryContainerProps: {
                className: 'ms-buybox__media-gallery'
            },
            telemetryContent: this.telemetryContent,
            callbacks: buyboxCallBacks.MicrosoftBuyboxCallbacks,
            title: getBuyboxProductTitle(props),
            description: getBuyboxProductDescription(props),
            unitOfMeasure: getBuyboxProductUnitOfMeasure(props),
            configure: getBuyboxProductConfigure(props, this.state, buyboxCallBacks.MicrosoftBuyboxCallbacks),
            findInStore: getBuyboxFindInStore(this.props, this.state, buyboxCallBacks.MicrosoftBuyboxCallbacks),
            price: getBuyboxProductPrice(props, this.state),
            addToCart: {
                ...getBuyboxAddToCart(
                    props,
                    this.state,
                    buyboxCallBacks.MicrosoftBuyboxCallbacks,
                    defaultMinimumKeyInPrice,
                    defaultMaximumKeyInPrice,
                    undefined,
                    hasAvailableProducts
                ),
                lsErrorBlock: this.getLsAddToCartErrors(this.state.lsErrorState, this.state.recipe, this.state.deal)
            },
            addToOrderTemplate: getBuyboxProductAddToOrderTemplate(props, this.state, buyboxCallBacks.MicrosoftBuyboxCallbacks),
            addToWishlist: getBuyboxProductAddToWishlist(props, this.state, buyboxCallBacks.MicrosoftBuyboxCallbacks),
            rating: !props.context.app.config.hideRating && getBuyboxProductRating(props),
            quantity: product.IsGiftCard
                ? undefined
                : getBuyboxProductQuantity(props, this.state, buyboxCallBacks.MicrosoftBuyboxCallbacks),
            inventoryLabel: getBuyBoxInventoryLabel(props),
            shopSimilarLook:
                this.props.config.enableShopSimilarLooks && !product.IsGiftCard
                    ? getBuyboxShopSimilarButton(props, ShopSimiliarButtonType.Looks)
                    : undefined,
            shopSimilarDescription:
                this.props.config.enableShopSimilarDescription && !product.IsGiftCard
                    ? getBuyboxShopSimilarButton(props, ShopSimiliarButtonType.Description)
                    : undefined,
            keyInPrice:
                this.props.config.enableKeyInPrice && this.state.isCustomPriceSelected
                    ? getBuyboxKeyInPrice(props, this.state, buyboxCallBacks.MicrosoftBuyboxCallbacks)
                    : undefined,
            quantityLimitsMessages: getQuantityLimitsMessages(props, this.state),
            max,
            ingredients: ObjectExtensions.isNullOrUndefined(recipe)
                ? undefined
                : Ingredients.GetIngredients(recipe, buyboxCallBacks.LsCallbacks),
            predefinedComments: ObjectExtensions.isNullOrUndefined(recipe)
                ? undefined
                : PredefinedComments.GetPredefinedComments(recipe, buyboxCallBacks.LsCallbacks),
            productModifierGroups: ObjectExtensions.isNullOrUndefined(recipe)
                ? undefined
                : ProductModifierGroups.GetProductModifierGroups(recipe, buyboxCallBacks.LsCallbacks),
            dealNodes:
                ObjectExtensions.isNullOrUndefined(deal) || ObjectExtensions.isNullOrUndefined(this.state.dealDefaultSelections)
                    ? undefined
                    : DealLines.GetDealLines(deal, this.state.dealDefaultSelections, buyboxCallBacks.LsCallbacks),
            lsCallbacks: buyboxCallBacks.LsCallbacks,
            recipeOrDeal: (this.state.deal as Deal) ?? (this.state.recipe as Recipe),
            dealDefaultSelections: this.state.dealDefaultSelections,
            lsAPILoaded: this.state.lsAPILoaded
        };

        return viewProps;
    }

    getLsAddToCartErrors(lsErrorState: ILsErrorState, recipe: Recipe | undefined, deal: Deal | undefined): JSX.Element | null {
        const recipeOrDeal = recipe ? recipe : deal;
        if (!recipeOrDeal) {
            return null;
        }
        const errorsForThisProduct = lsErrorState ?? [];
        if (errorsForThisProduct.length === 0) {
            return null;
        }
        return (
            <LSFirstAlert color='danger' ariaLabel={`All errors in the order`} alertHeader='There are some issues in your order'>
                <LSFirstAlertList>
                    {errorsForThisProduct.map((error, index) => {
                        if (!error.errorMessage) {
                            return null;
                        }
                        return (
                            <LSFirstAlertListItem key={index} className='lsfirst-alert-line'>
                                {error.errorMessageCaption ? `${error.errorMessageCaption}: ` : null}
                                {error.errorMessage}
                            </LSFirstAlertListItem>
                        );
                    })}
                </LSFirstAlertList>
            </LSFirstAlert>
        );
    }

    public _updatePrice(newPrice: ProductPrice | undefined, customPrice: number | undefined = this.state.keyInPriceAmount): void {
        if (this.state.isCustomPriceSelected && newPrice) {
            newPrice.CustomerContextualPrice = customPrice;
        }
        this.setState({ productPrice: newPrice });
    }

    public readonly _updateDimensions = async (): Promise<void> => {
        const {
            data: {
                product: { result: product }
            },
            context: {
                actionContext,
                request: {
                    apiSettings: { channelId }
                }
            }
        } = this.props;

        const productDimensions = this.props.data.productDimensions.result;

        const hasProductDimensions = ArrayExtensions.hasElements(productDimensions);

        if (!product || !hasProductDimensions) {
            return;
        }

        const dimensionsToUpdate: { [id: number]: string } = { ...this.dimensions };
        this.setState({ isUpdatingDimension: true, isUpdatingDeliveryOptions: true });

        // Step 1: Clear error state to display relevant errors
        if (this.state.errorState.otherError || this.state.errorState.quantityError) {
            const clearErrorState = { ...this.state.errorState };
            clearErrorState.otherError = undefined;
            if (this.state.errorState.errorHost === 'ADDTOCART') {
                clearErrorState.quantityError = undefined;
                clearErrorState.errorHost = undefined;
            }
            this.setState({ errorState: clearErrorState });
        }

        // Step 2: Clear any errors indicating the dimension wasn't selected
        for (const key of Object.keys(dimensionsToUpdate)) {
            if (this.state.errorState.configureErrors[key]) {
                const errorState = { ...this.state.errorState };
                errorState.configureErrors[key] = undefined;

                this.setState({ errorState });
            }
        }

        const variantProduct: SimpleProduct | null = await this._updateProductDimensionsWithAvailabilities(dimensionsToUpdate);

        if (variantProduct) {
            // Step 5. Use these dimensions hydrate the inventory. Wrap this in a promise
            // so that places like add to cart can await it
            const newAvailableQuantity = await getProductAvailabilitiesForSelectedVariant(
                new ProductAvailabilitiesForSelectedVariantInput(variantProduct.RecordId, channelId),
                actionContext
            );

            const newShippingQuantity = newAvailableQuantity?.find(shipping => shipping.deliveryType === DeliveryMode.shipping);
            const isCustompriceSelected = variantProduct.Dimensions?.find(
                dimension =>
                    dimension.DimensionTypeValue === 4 &&
                    dimension.DimensionValue &&
                    dimension.DimensionValue.Value?.toLowerCase() === 'custom'
            );
            if (isCustompriceSelected) {
                this.setState({ isCustomPriceSelected: true });
            } else {
                // Remove custom amount error when unselect the custom amount
                const errorState = { ...this.state.errorState };
                errorState.customAmountError = undefined;

                this.setState({ isCustomPriceSelected: false, isPriceKeyedIn: false, errorState });
            }

            if (newShippingQuantity) {
                this.setState({ productAvailableQuantity: newShippingQuantity });
            } else {
                this.setState({ productAvailableQuantity: undefined });
            }

            // Step 6. Use these dimensions hydrate the product price.
            const newPrice = await getPriceForSelectedVariant(
                new PriceForSelectedVariantInput(variantProduct.RecordId, channelId),
                actionContext
            );

            if (newPrice) {
                this._updatePrice(newPrice);
            }

            const retailMultiplePickupFeatureState = this.props.data.featureState.result?.find(
                featureState => featureState.Name === 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature'
            );

            // Step 7. Use these dimensions hydrate the product delivery options.
            const newDeliveryOptions = await getDeliveryOptionsForSelectedVariant(
                new GetDeliveryOptionsForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId,
                    undefined,
                    undefined,
                    retailMultiplePickupFeatureState?.IsEnabled
                ),
                actionContext
            );

            if (newDeliveryOptions) {
                this.setState({ productDeliveryOptions: newDeliveryOptions });
            }

            await this._updateQuantitiesInState(variantProduct);
        }
    };

    public async _updateProductDimensionsWithAvailabilities(dimensionsToUpdate: { [id: number]: string }): Promise<SimpleProduct | null> {
        const {
            data: {
                product: { result: product }
            },
            context: {
                actionContext,
                request: {
                    apiSettings: { channelId }
                }
            }
        } = this.props;

        const productDimensions = this.props.data.productDimensions.result!;

        // Step 3, Build the actually selected dimensions, prioritizing the information in state
        // over the information in data
        const mappedDimensions = productDimensions
            .map(dimension => {
                const dimensions = (dimension.dimensionValuesWithInventory ??
                    dimension.DimensionValues ??
                    []) as IDimensionValueForSelectedVariant[];
                return {
                    DimensionTypeValue: dimension.DimensionTypeValue,
                    DimensionValue:
                        dimensions.find(
                            value => value.DimensionValue?.RecordId.toString() === dimensionsToUpdate[dimension.DimensionTypeValue]
                        )?.DimensionValue ?? dimension.DimensionValue,
                    ExtensionProperties: dimension.ExtensionProperties
                };
            })
            .filter(dimension => dimension.DimensionValue);
        setDimensionValuesToQuery(this.props.context.actionContext.requestContext.url.requestUrl, mappedDimensions);

        // Step 4. Use these dimensions hydrate the product. Wrap this in a promise
        // so that places like add to cart can await it
        const selectedProductPromise = getSelectedVariant(
            new SelectedVariantInput(product!.MasterProductId ? product!.MasterProductId : product!.RecordId, channelId),
            actionContext
        );
        this.setState({ selectedProduct: selectedProductPromise });

        const variantProduct = await selectedProductPromise;
        if (variantProduct) {
            await getDimensionsForSelectedVariant(
                new GetDimensionsForSelectedVariantInput(
                    variantProduct.MasterProductId ? variantProduct.MasterProductId : variantProduct.RecordId,
                    channelId,
                    mappedDimensions
                ),
                actionContext
            );
        }

        return variantProduct;
    }

    public async _updateQuantitiesInState(product: SimpleProduct): Promise<void> {
        const isOrderQuantityLimitsFeatureEnabled = await this._isOrderQuantityLimitsFeatureEnabled();
        if (isOrderQuantityLimitsFeatureEnabled && product) {
            this.setState({
                quantity: product.Behavior?.DefaultQuantity || 1,
                min: product.Behavior?.MinimumQuantity || 1,

                // If max by feature in default order settings is not defined then use max from site settings or default max 10.
                max:
                    product.Behavior?.MaximumQuantity && product.Behavior?.MaximumQuantity > 0
                        ? product.Behavior?.MaximumQuantity
                        : this.props.context.app.config.maxQuantityForCartLineItem || 10
            });
        } else {
            this.setState({
                min: 1,
                max: this.props.context.app.config.maxQuantityForCartLineItem || 10
            });
        }
    }

    public readonly _getDropdownName = (dimensionType: number, resources: ILsfirstBuyboxResources): string => {
        const isGiftCard = this.props.data.product.result?.IsGiftCard;

        switch (dimensionType) {
            case 1: // ProductDimensionType.Color
                return resources.productDimensionTypeColor;
            case 2: // ProductDimensionType.Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // ProductDimensionType.Size
                return resources.productDimensionTypeSize;
            case 4: // ProductDimensionType.Style
                return isGiftCard ? resources.productDimensionTypeAmount : resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };

    public async _isOrderQuantityLimitsFeatureEnabled(): Promise<boolean> {
        const defaultOrderQuantityLimitsFeatureConfig = this.props.context?.request?.app?.platform?.enableDefaultOrderQuantityLimits;
        if (defaultOrderQuantityLimitsFeatureConfig === 'none') {
            return false;
        }

        const featureStatuses = await this.props.data.featureState;
        const isFeatureEnabledInHq = featureStatuses?.find(
            featureState => featureState.Name === RetailDefaultOrderQuantityLimitsFeatureName
        )?.IsEnabled;
        if (!isFeatureEnabledInHq) {
            return false;
        }

        if (defaultOrderQuantityLimitsFeatureConfig === 'all') {
            return true;
        }
        let customerInfo;
        try {
            customerInfo = await this.props.data.customerInformation;
        } catch (error) {
            this.props.telemetry.information(error as string);
            this.props.telemetry.debug('Unable to receive Customer Information. May be user is not authorized');
            return false;
        }

        return (
            customerInfo &&
            ((defaultOrderQuantityLimitsFeatureConfig === 'b2b' && customerInfo.IsB2b) ||
                (defaultOrderQuantityLimitsFeatureConfig === 'b2c' && !customerInfo.IsB2b))
        );
    }
}

export default Buybox;
