import {
    ILsComments,
    ILshDealLine,
    ILshDealModifier,
    ILshDealModifierGroup,
    ILshIngredient,
    ILsPredefinedCommentGroup,
    ILshProductModifierGroup
} from '../../../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { DataModelBuilder } from '../../../../../Domain/Common/DataModelBuilder/DataModelBuilder';
import { IDataModelBuilder } from '../../../../../Domain/Common/DataModelBuilder/IDataModelBuilder';
import { DealLine } from './DealLine';

export class LshDealLine implements ILshDealLine {
    BomId?: string | undefined;
    BomVersionItemId?: string | undefined;
    Comments?: ILsComments | undefined;
    DealModifierGroups?: ILshDealModifierGroup[] | undefined;
    DealModifierToSwapWith?: ILshDealModifier | undefined;
    HideOnPos: boolean = false;
    Ingredients?: ILshIngredient[] | undefined;
    ItemId?: string | undefined;
    Name?: string | undefined;
    PredefinedComments?: ILsPredefinedCommentGroup[] | undefined;
    Price: number = -1;
    ProductModifierGroups?: ILshProductModifierGroup[] | undefined;
    Quantity: number = -1;
    ProductId: number = -1;
}

export class LshDealLineBuilder extends DataModelBuilder<LshDealLine> {
    constructor() {
        super(LshDealLine);
    }
    public Copy(dealLine: LshDealLine): IDataModelBuilder<LshDealLine> {
        this.HideOnPos(dealLine.HideOnPos);
        this.Price(dealLine.Price);
        this.Quantity(dealLine.Quantity);
        this.Productid(dealLine.ProductId);

        if (dealLine.BomId) {
            this.BomId(dealLine.BomId);
        }

        if (dealLine.BomVersionItemId) {
            this.BomVersionItemId(dealLine.BomVersionItemId);
        }

        if (dealLine.Comments) {
            this.Comments(dealLine.Comments);
        }

        if (dealLine.DealModifierGroups) {
            this.DealModifierGroups(dealLine.DealModifierGroups);
        }

        if (dealLine.DealModifierToSwapWith) {
            this.DealModifierToSwapWith(dealLine.DealModifierToSwapWith);
        }

        if (dealLine.Ingredients) {
            this.Ingredients(dealLine.Ingredients);
        }

        if (dealLine.ItemId) {
            this.ItemId(dealLine.ItemId);
        }

        if (dealLine.Name) {
            this.Name(dealLine.Name);
        }

        if (dealLine.PredefinedComments) {
            this.PredefinedComments(dealLine.PredefinedComments);
        }

        if (dealLine.ProductModifierGroups) {
            this.ProductModifierGroups(dealLine.ProductModifierGroups);
        }

        return this;
    }

    HideOnPos(HideOnPos: boolean) {
        this.object.HideOnPos = HideOnPos;
        return this;
    }

    Price(Price: number) {
        this.object.Price = Price;
        return this;
    }

    Quantity(Quantity: number) {
        this.object.Quantity = Quantity;
        return this;
    }

    Productid(ProductId: number) {
        this.object.ProductId = ProductId;
        return this;
    }

    BomId(BomId: string) {
        this.object.BomId = BomId;
        return this;
    }

    BomVersionItemId(BomVersionItemId: string) {
        this.object.BomVersionItemId = BomVersionItemId;
        return this;
    }

    Comments(Comments: ILsComments) {
        this.object.Comments = Comments;
        return this;
    }

    DealModifierGroups(DealModifierGroups: ILshDealModifierGroup[]) {
        this.object.DealModifierGroups = DealModifierGroups;
        return this;
    }

    DealModifierToSwapWith(DealModifierToSwapWith: ILshDealModifier) {
        this.object.DealModifierToSwapWith = DealModifierToSwapWith;
        return this;
    }

    Ingredients(Ingredients: ILshIngredient[]) {
        this.object.Ingredients = Ingredients;
        return this;
    }

    ItemId(ItemId: string) {
        this.object.ItemId = ItemId;
        return this;
    }

    Name(Name: string) {
        this.object.Name = Name;
        return this;
    }

    PredefinedComments(PredefinedComments: ILsPredefinedCommentGroup[]) {
        this.object.PredefinedComments = PredefinedComments;
        return this;
    }

    ProductModifierGroups(ProductModifierGroups: ILshProductModifierGroup[]) {
        this.object.ProductModifierGroups = ProductModifierGroups;
        return this;
    }
}

export class LshDealLineConverter {
    // Converts a DealLine to an LshDealLine
    public static FromDealLine(dealLine: DealLine): LshDealLine {
        const lshDealLine = new LshDealLineBuilder().Copy(dealLine);
        return lshDealLine.Build();
    }
}
