import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';

import {
    ILsCartLine,
    ILsComments,
    ILshDealLine,
    ILshIngredient,
    ILsPredefinedCommentGroup,
    ILshProductModifierGroup
} from '../../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { DataModelBuilder } from '../../../../Domain/Common/DataModelBuilder/DataModelBuilder';

export class LsCartLine implements ILsCartLine {
    CartId?: string | undefined;
    Comments?: ILsComments | undefined;
    CustomerId?: string | undefined;
    DealLines?: ILshDealLine[] | undefined;
    Description?: string | undefined;
    Ingredients?: ILshIngredient[] | undefined;
    IsPriceKeyedIn: boolean = false;
    IsPriceOverridden: boolean = false;
    IsVoided: boolean = false;
    ItemId?: string | undefined;
    LineId?: string | undefined;
    LineNumber: number = 0;
    LshCartLineGroupId?: string | undefined;
    LstSeatNumber: number = 0;
    OriginalLineId?: string | undefined;
    OriginalPrice: number = 0;
    PredefinedComments?: ILsPredefinedCommentGroup[] | undefined;
    Price: number = 0;
    ProductId: number = 0;
    ProductModifierGroups?: ILshProductModifierGroup[] | undefined;
    Quantity: number = 0;
    SalesDate?: Date | undefined;
    UnitOfMeasureDecimalPrecision: number = 0;
}

export class LsCartLineBuilder extends DataModelBuilder<LsCartLine> {
    constructor() {
        super(LsCartLine);
    }

    public Copy(lsCartLine: LsCartLine): LsCartLineBuilder {
        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.CartId)) {
            this.CartId(lsCartLine.CartId);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.Comments)) {
            this.Comments(lsCartLine.Comments);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.CustomerId)) {
            this.CustomerId(lsCartLine.CustomerId);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.DealLines)) {
            this.DealLines(lsCartLine.DealLines);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.Description)) {
            this.Description(lsCartLine.Description);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.Ingredients)) {
            this.Ingredients(lsCartLine.Ingredients);
        }

        this.IsPriceKeyedIn(lsCartLine.IsPriceKeyedIn);
        this.IsPriceOverridden(lsCartLine.IsPriceOverridden);
        this.IsVoided(lsCartLine.IsVoided);

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.ItemId)) {
            this.ItemId(lsCartLine.ItemId);
        }

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.LineId)) {
            this.LineId(lsCartLine.LineId);
        }

        this.LineNumber(lsCartLine.LineNumber);

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.LshCartLineGroupId)) {
            this.LshCartLineGroupId(lsCartLine.LshCartLineGroupId);
        }

        this.LstSeatNumber(lsCartLine.LstSeatNumber);

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.OriginalLineId)) {
            this.OriginalLineId(lsCartLine.OriginalLineId);
        }

        this.OriginalPrice(lsCartLine.OriginalPrice);

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.PredefinedComments)) {
            this.PredefinedComments(lsCartLine.PredefinedComments);
        }

        this.Price(lsCartLine.Price);
        this.ProductId(lsCartLine.ProductId);

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.ProductModifierGroups)) {
            this.ProductModifierGroups(lsCartLine.ProductModifierGroups);
        }

        this.Quantity(lsCartLine.Quantity);

        if (!ObjectExtensions.isNullOrUndefined(lsCartLine.SalesDate)) {
            this.SalesDate(lsCartLine.SalesDate);
        }

        this.UnitOfMeasureDecimalPrecision(lsCartLine.UnitOfMeasureDecimalPrecision);

        return this;
    }

    public CartId(value: string): LsCartLineBuilder {
        this.object.CartId = value;
        return this;
    }

    public Comments(value: ILsComments): LsCartLineBuilder {
        this.object.Comments = value;
        return this;
    }

    public CustomerId(value: string): LsCartLineBuilder {
        this.object.CustomerId = value;
        return this;
    }

    public DealLines(value: ILshDealLine[]): LsCartLineBuilder {
        this.object.DealLines = value;
        return this;
    }

    public Description(value: string): LsCartLineBuilder {
        this.object.Description = value;
        return this;
    }

    public Ingredients(value: ILshIngredient[]): LsCartLineBuilder {
        this.object.Ingredients = value;
        return this;
    }

    public IsPriceKeyedIn(value: boolean): LsCartLineBuilder {
        this.object.IsPriceKeyedIn = value;
        return this;
    }

    public IsPriceOverridden(value: boolean): LsCartLineBuilder {
        this.object.IsPriceOverridden = value;
        return this;
    }

    public IsVoided(value: boolean): LsCartLineBuilder {
        this.object.IsVoided = value;
        return this;
    }

    public ItemId(value: string): LsCartLineBuilder {
        this.object.ItemId = value;
        return this;
    }

    public LineId(value: string): LsCartLineBuilder {
        this.object.LineId = value;
        return this;
    }

    public LineNumber(value: number): LsCartLineBuilder {
        this.object.LineNumber = value;
        return this;
    }

    public LshCartLineGroupId(value: string): LsCartLineBuilder {
        this.object.LshCartLineGroupId = value;
        return this;
    }

    public LstSeatNumber(value: number): LsCartLineBuilder {
        this.object.LstSeatNumber = value;
        return this;
    }

    public OriginalLineId(value: string): LsCartLineBuilder {
        this.object.OriginalLineId = value;
        return this;
    }

    public OriginalPrice(value: number): LsCartLineBuilder {
        this.object.OriginalPrice = value;
        return this;
    }

    public PredefinedComments(value: ILsPredefinedCommentGroup[]): LsCartLineBuilder {
        this.object.PredefinedComments = value;
        return this;
    }

    public Price(value: number): LsCartLineBuilder {
        this.object.Price = value;
        return this;
    }

    public ProductId(value: number): LsCartLineBuilder {
        this.object.ProductId = value;
        return this;
    }

    public ProductModifierGroups(value: ILshProductModifierGroup[]): LsCartLineBuilder {
        this.object.ProductModifierGroups = value;
        return this;
    }

    public Quantity(value: number): LsCartLineBuilder {
        this.object.Quantity = value;
        return this;
    }

    public SalesDate(value: Date): LsCartLineBuilder {
        this.object.SalesDate = value;
        return this;
    }

    public UnitOfMeasureDecimalPrecision(value: number): LsCartLineBuilder {
        this.object.UnitOfMeasureDecimalPrecision = value;
        return this;
    }
}
