//import { ReactElement } from 'enzyme/node_modules/@types/react';
import * as React from 'react';

import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';

import { ILshIngredient } from '../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import classnames from 'classnames';
import { ILsFirstBuyboxCallbacks } from '../../../../modules/lsfirst-buybox/ILsFirstBuyboxCallbacks';
import { DealLine } from '../DataModel/Deals/DealLines/DealLine';
import { LsFirstBuyboxContext } from '../../../../modules/lsfirst-buybox/Context/LsFirstBuyboxContext';
import { makeAlertFromLsErrorArray } from '../../../../modules/lsfirst-buybox/components/LsFirstAlert';

interface IObjectWithIlshIngredientProp {
    Ingredients?: ILshIngredient[];
}

export class Ingredients {
    public static GetIngredients(
        objectWithIngredientsArray: IObjectWithIlshIngredientProp,
        callbacks: ILsFirstBuyboxCallbacks,
        dealLine?: DealLine
    ): React.ReactNode | undefined {
        if (
            ObjectExtensions.isNullOrUndefined(objectWithIngredientsArray.Ingredients) ||
            objectWithIngredientsArray.Ingredients.length === 0
        ) {
            return undefined;
        } else {
            const ingredientsAsElement = new Ingredients().CreateReactElement(objectWithIngredientsArray.Ingredients, callbacks, dealLine);
            return ingredientsAsElement;
        }
    }

    private CreateReactElement(ingredients: ILshIngredient[], callbacks: ILsFirstBuyboxCallbacks, dealLine?: DealLine): React.ReactElement {
        const ingredientsAsElement = (
            <LsFirstBuyboxContext.Consumer>
                {({ lsErrorState }) => {
                    const thisProductErrors = lsErrorState ?? [];
                    const ingredientErrors = thisProductErrors.filter(lsError => lsError.errorLocation === 'Ingredients');
                    return (
                        <div>
                            <h2>Ingredients</h2>
                            <br></br>
                            {makeAlertFromLsErrorArray(ingredientErrors, 'There are some issues here', 'Errors for Ingredients')}
                            <table className='table ingredients-table'>
                                <thead className='ingredients-thead'>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ingredients.map((ingredient, index) => {
                                        return (
                                            <tr className='ingredients-tr' key={index}>
                                                <td className='ingredients-td'>{ingredient.Name}</td>
                                                <td
                                                    className={classnames('ingredients-td', {
                                                        // hide if ingredient.ReducePrice is false
                                                        'ingredients-hidden': ingredient.ReducePrice
                                                    })}
                                                >
                                                    ${ingredient.Price}
                                                </td>
                                                <td>
                                                    <div className='ingredients-quantityDisplay'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-primary ingredients-qtybtn'
                                                            disabled={this.ShouldDisableDecrease(ingredient)}
                                                            onClick={() => this.UpdateQuantity(ingredient, -1, callbacks, dealLine)}
                                                        >
                                                            -
                                                        </button>
                                                        <span>{ingredient.Quantity}</span>
                                                        <button
                                                            type='button'
                                                            className='btn btn-primary ingredients-qtybtn'
                                                            disabled={this.ShouldDisableIncrease(ingredient)}
                                                            onClick={() => this.UpdateQuantity(ingredient, 1, callbacks, dealLine)}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <br></br>
                        </div>
                    );
                }}
            </LsFirstBuyboxContext.Consumer>
        );

        return ingredientsAsElement;
    }

    private ShouldDisableIncrease(ingredient: ILshIngredient): boolean {
        return ingredient.Quantity >= ingredient.BomMaxQuantity;
    }

    private ShouldDisableDecrease(ingredient: ILshIngredient): boolean {
        return ingredient.Quantity <= ingredient.BomMinQuantity;
    }

    private UpdateQuantity(ingredient: ILshIngredient, change: number, callbacks: ILsFirstBuyboxCallbacks, dealLine?: DealLine): void {
        const newQuantity = ingredient.Quantity + change;
        const canUpdate: boolean = newQuantity >= 0 && (ingredient.BomMaxQuantity === 0 || newQuantity <= ingredient.BomMaxQuantity);
        if (canUpdate) {
            const temporaryIngredient = { ...ingredient };
            temporaryIngredient.Quantity = ingredient.Quantity + change;

            callbacks.updateRecipeOrDealLineIngredientModifications(temporaryIngredient, dealLine);

            if (change < 0 && ingredient.ReducePrice === false) {
                return;
            }

            callbacks.updateProductPriceDueToModifications(ingredient.Price * change);
        }
    }
}
