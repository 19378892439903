import { GenericClassBase } from '../../../Platform/Common/Generics/GenericClassBase';
import { IDataModelBuilder } from './IDataModelBuilder';

export abstract class DataModelBuilder<Type> extends GenericClassBase<Type> implements IDataModelBuilder<Type> {
    public Build(): Type {
        return this.object;
    }

    public abstract Copy(objectToBuild: Type): IDataModelBuilder<Type>;
}
