import { SimpleProductClass } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { DataModelBuilder } from '../../../Domain/Common/DataModelBuilder/DataModelBuilder';
import { IDataModelBuilder } from '../../../Domain/Common/DataModelBuilder/IDataModelBuilder';

export class SimpleProductClassBuilder<Type extends SimpleProductClass> extends DataModelBuilder<Type> {
    public Copy(simpleProduct: Type): IDataModelBuilder<Type> {
        this.ItemId(simpleProduct.ItemId);
        this.Name(simpleProduct.Name);
        this.RecordId(simpleProduct.RecordId);
        this.DefaultUnitOfMeasure(simpleProduct.DefaultUnitOfMeasure);
        this.AdjustedPrice(simpleProduct.AdjustedPrice);
        this.BasePrice(simpleProduct.BasePrice);
        this.Price(simpleProduct.Price);

        return this;
    }

    public ItemId(value: string): SimpleProductClassBuilder<Type> {
        this.object.ItemId = value;
        return this;
    }

    public Name(value: string): SimpleProductClassBuilder<Type> {
        this.object.Name = value;
        return this;
    }

    public RecordId(value: number): SimpleProductClassBuilder<Type> {
        this.object.RecordId = value;
        return this;
    }

    public DefaultUnitOfMeasure(value: string): SimpleProductClassBuilder<Type> {
        this.object.DefaultUnitOfMeasure = value;
        return this;
    }

    public AdjustedPrice(value: number): SimpleProductClassBuilder<Type> {
        this.object.AdjustedPrice = value;
        return this;
    }

    public BasePrice(value: number): SimpleProductClassBuilder<Type> {
        this.object.BasePrice = value;
        return this;
    }

    public Price(value: number): SimpleProductClassBuilder<Type> {
        this.object.Price = value;
        return this;
    }
}
