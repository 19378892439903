import React, { FC } from 'react';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
interface IReactViewProps {
    children: React.ReactNode;
}

export const RecipeView: FC<IReactViewProps> = ({ children }) => {
    // render if at least one child is not null or if children is somehow not an array
    const shouldRender = Array.isArray(children) ? children.find(child => !ObjectExtensions.isNullOrUndefined(child)) : true;
    return shouldRender ? <div className='card card-body RecipeView'>{children}</div> : null;
};
