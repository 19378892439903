import React from 'react';
import { LSFirstAlert, LSFirstAlertList, LSFirstAlertListItem } from '.';
import { ILsError } from '../../State/LsFirstBuyboxState';

export function makeAlertFromLsErrorArray(errors: ILsError[], alertHeader: string = '', ariaLabel: string = ''): JSX.Element | null {
    if (errors.length === 0) {
        return null;
    }
    return (
        <LSFirstAlert color='danger' ariaLabel={ariaLabel} alertHeader={alertHeader}>
            <LSFirstAlertList>
                {errors.map((error, index) => {
                    if (!error.errorMessage) {
                        return null;
                    }
                    return (
                        <LSFirstAlertListItem key={index} className='lsfirst-alert-line'>
                            {error.errorMessage}
                        </LSFirstAlertListItem>
                    );
                })}
            </LSFirstAlertList>
        </LSFirstAlert>
    );
}
