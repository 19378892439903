import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';

import {
    ILsComments,
    ILshDealLine,
    ILshDealModifier,
    ILshDealModifierGroup
} from '../../../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { Recipe, RecipeBuilder } from '../../Recipe';

export class DealLine extends Recipe implements ILshDealLine {
    BomId?: string;
    BomVersionItemId?: string;
    Comments?: ILsComments;
    DealModifierGroups?: ILshDealModifierGroup[];
    DealModifierToSwapWith?: ILshDealModifier;
    HideOnPos: boolean = false;
    DealLineNumber: number = 1;
    ProductId: number = this.RecordId;
    Quantity: number = 1;
}

export class DealLineBuilder extends RecipeBuilder<DealLine> {
    constructor() {
        super(DealLine);
    }

    public Copy(dealLine: DealLine) {
        super.Copy(dealLine);

        new DealLineProperties().SetDealLineInterfacePropertiesOnBuilder(dealLine, this);

        this.DealLineNumber(dealLine.DealLineNumber);

        if (!ObjectExtensions.isNullOrUndefined(dealLine.Comments)) {
            this.Comments(dealLine.Comments);
        }

        this.ProductId(dealLine.RecordId);

        return this;
    }

    DealModifierGroups(DealModifierGroups: ILshDealModifierGroup[]): DealLineBuilder {
        this.object.DealModifierGroups = DealModifierGroups;
        return this;
    }

    BomId(BomId: string): DealLineBuilder {
        this.object.BomId = BomId;
        return this;
    }

    BomVersionItemId(BomVersionItemId: string): DealLineBuilder {
        this.object.BomVersionItemId = BomVersionItemId;
        return this;
    }

    DealModifierToSwapWith(DealModifierToSwapWith: ILshDealModifier): DealLineBuilder {
        this.object.DealModifierToSwapWith = DealModifierToSwapWith;
        return this;
    }

    Comments(Comments: ILsComments): DealLineBuilder {
        this.object.Comments = Comments;
        return this;
    }

    DealLineNumber(value: number): DealLineBuilder {
        this.object.DealLineNumber = value;
        return this;
    }

    HideOnPos(HideOnPos: boolean): DealLineBuilder {
        this.object.HideOnPos = HideOnPos;
        return this;
    }

    Quantity(Quantity: number): DealLineBuilder {
        this.object.Quantity = Quantity;
        return this;
    }

    ProductId(ProductId: number): DealLineBuilder {
        this.object.RecordId = ProductId;
        this.object.ProductId = ProductId;
        return this;
    }

    RecordId(RecordId: number): DealLineBuilder {
        this.object.RecordId = RecordId;
        this.object.ProductId = RecordId;
        return this;
    }
}

export class DealLineConverter {
    /**
     * Converts a given ILshDealLine to a DealLine.
     *
     * @remarks
     *      The difference between the two types is that DealLine must track its selected comments and that DealLine
     * actually extends Recipe.
     *
     * @param iLshDealLine ILshDealLine. The ILshDealLine to convert.
     * @param dealLineNumber Number. The position of this DealLine within the Deal.
     * @returns DealLine. The converted DealLine.
     */
    public FromILshDealLine(iLshDealLine: ILshDealLine, dealLineNumber: number): DealLine {
        const dealLineBuilder = new DealLineBuilder();
        new DealLineProperties().SetDealLineInterfacePropertiesOnBuilder(iLshDealLine, dealLineBuilder);
        dealLineBuilder.DealLineNumber(dealLineNumber);
        return dealLineBuilder.Build();
    }
}

class DealLineProperties {
    public SetDealLineInterfacePropertiesOnBuilder(dealLine: ILshDealLine, dealLineBuilder: DealLineBuilder): void {
        if (!ObjectExtensions.isNullOrUndefined(dealLine.BomId)) {
            dealLineBuilder.BomId(dealLine.BomId);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.BomVersionItemId)) {
            dealLineBuilder.BomVersionItemId(dealLine.BomVersionItemId);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.Comments)) {
            dealLineBuilder.Comments(dealLine.Comments);
            dealLineBuilder.SelectedComments(dealLine.Comments);
        }

        if (dealLine.DealModifierGroups) {
            dealLineBuilder.DealModifierGroups(dealLine.DealModifierGroups);
        }

        if (dealLine.DealModifierToSwapWith) {
            dealLineBuilder.DealModifierToSwapWith(dealLine.DealModifierToSwapWith);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.Ingredients)) {
            dealLineBuilder.Ingredients(dealLine.Ingredients);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.ItemId)) {
            dealLineBuilder.ItemId(dealLine.ItemId);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.Name)) {
            dealLineBuilder.Name(dealLine.Name);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.PredefinedComments)) {
            dealLineBuilder.PredefinedComments(dealLine.PredefinedComments);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.Price)) {
            dealLineBuilder.Price(dealLine.Price);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.ProductModifierGroups)) {
            dealLineBuilder.ProductModifierGroups(dealLine.ProductModifierGroups);
        }

        if (!ObjectExtensions.isNullOrUndefined(dealLine.ProductId)) {
            dealLineBuilder.ProductId(dealLine.ProductId);
        }

        dealLineBuilder.HideOnPos(dealLine.HideOnPos);

        dealLineBuilder.Quantity(dealLine.Quantity);
    }
}
