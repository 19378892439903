import { ILshIngredient, ILshProductModifierGroup } from '../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { Deal } from '../DataModel/Deals/Deal';
//import { ReactElement } from 'enzyme/node_modules/@types/react';
import * as React from 'react';
import { Ingredients } from './Ingredients';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { ILsDealDefaultSelections } from '../../../../modules/lsfirst-buybox';
import { PredefinedComments } from './PredefinedComments';
import { ProductModifierGroups } from './ProductModifierGroups';
import { DealModifierGroups } from './DealModifierGroups';
import { ILsFirstBuyboxCallbacks } from '../../../../modules/lsfirst-buybox/ILsFirstBuyboxCallbacks';
import { DealLine } from '../DataModel/Deals/DealLines/DealLine';
import { DealModifier } from '../DataModel/Deals/DealModifierGroups/DealModifiers/DealModifier';

export class DealLines {
    public static GetDealLines(
        deal: Deal,
        selections: ILsDealDefaultSelections,
        callbacks: ILsFirstBuyboxCallbacks
    ): React.ReactNode | undefined {
        if (!deal.DealLines || deal.DealLines.length === 0) {
            return undefined;
        } else {
            return new DealLines().CreateReactElement(deal.DealLines, selections, callbacks);
        }
    }

    public static FindIngredientsArrayForSelectedDealLineModifier(
        dealLine: DealLine,
        selections: ILsDealDefaultSelections
    ): ILshIngredient[] {
        const selectedDealModifierId = selections[dealLine.ItemId];
        if (dealLine.ItemId === selectedDealModifierId) {
            // no dealModifier selected, return top level ILshIngredient[]
            // also a deal line doesn't need to have an ingredients array defined,
            // if so we just return an empty array
            return dealLine.Ingredients ?? [];
        } else {
            const dealModifer = DealModifier.GetDealModifierById(dealLine, selectedDealModifierId);
            if (!dealModifer) {
                // Object.keys({selections})[0] converts the variable's own name to string.
                // throw an error because if we run the FindIngredientsArray... function we expect
                // dealModifer not to be null here
                throw new Error(`This dealLine has no corresponding ID in ${Object.keys({ selections })[0]}`);
            }
            return dealModifer.Ingredients ?? [];
        }
    }

    public static FindProductModifierGroupsForSelectedDealLineModifier(
        dealLine: DealLine,
        selections: ILsDealDefaultSelections
    ): ILshProductModifierGroup[] {
        if (ObjectExtensions.isNullOrUndefined(dealLine.ItemId)) {
            throw new Error('[FindProductModifierGroupsForSelectedDealLineModifier] Unexpected undefined at DealLine.ItemId');
        }
        const selectedDealModifierId = selections[dealLine.ItemId];
        if (dealLine.ItemId === selectedDealModifierId) {
            // no dealModifier selected, return top level ILshProductModifierGroups[]
            return dealLine.ProductModifierGroups ?? [];
        } else {
            const dealModifer = DealModifier.GetDealModifierById(dealLine, selectedDealModifierId);
            if (ObjectExtensions.isNullOrUndefined(dealModifer)) {
                throw new Error('[FindProductModifierGroupsForSelectedDealLineModifier] Unexpected DealModifier not found');
            }
            return dealModifer.ProductModifierGroups ?? [];
        }
    }

    private CreateReactElement(
        dealLines: DealLine[],
        selections: ILsDealDefaultSelections,
        callbacks: ILsFirstBuyboxCallbacks
    ): React.ReactElement {
        const dealLineNodes = dealLines.map(dealLine => {
            // need to be aware that a dealLine can have modifierGroups, and so the ingredients array and productmodifiergroups will change accordingly
            const ingredientsToRender = {
                Ingredients: DealLines.FindIngredientsArrayForSelectedDealLineModifier(dealLine, selections)
            };

            const productModifierGroupsToRender = {
                ProductModifierGroups: DealLines.FindProductModifierGroupsForSelectedDealLineModifier(dealLine, selections)
            };
            if (ObjectExtensions.isNullOrUndefined(dealLine.ItemId)) {
                throw new Error('[CreateReactElement] Unexpected undefined at DealLine.ItemId');
            }
            return (
                <div key={dealLine.ProductId} className='card mb-3'>
                    <div className='card-body'>
                        {DealModifierGroups.GetDealModifierGroups(dealLine, selections[dealLine.ItemId], callbacks)}
                        {Ingredients.GetIngredients(ingredientsToRender, callbacks, dealLine)}
                        {ProductModifierGroups.GetProductModifierGroups(productModifierGroupsToRender, callbacks, dealLine)}
                        {PredefinedComments.GetPredefinedComments(dealLine, callbacks)}
                    </div>
                </div>
            );
        });
        return <div>{dealLineNodes}</div>;
    }
}
