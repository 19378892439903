/* eslint-disable no-duplicate-imports */
import * as React from 'react';

import { IncrementalQuantity, ITelemetryContent, Module, Node } from '@msdyn365-commerce-modules/utilities';

import {
    IBuyboxAddToOrderTemplateViewProps,
    IBuyboxAddToWishlistViewProps,
    IBuyboxCallbacks,
    IBuyboxFindInStoreViewProps,
    IBuyboxKeyInPriceViewProps,
    IBuyboxProductConfigureDropdownViewProps,
    IBuyboxProductConfigureViewProps,
    IBuyboxProductQuantityViewProps,
    IBuyboxShopSimilarLookViewProps
} from '@msdyn365-commerce-modules/buybox';

import { ILsfirstBuyboxProps, ILsfirstBuyboxResources } from './lsfirst-buybox.props.autogenerated';
import { ILsfirstBuyboxData } from './lsfirst-buybox.data';
import { ILsBuyboxViewProps } from './ViewProps/LsFirstBuyboxViewProps';
import { LsFirstBuyboxContext } from './Context/LsFirstBuyboxContext';
import { ILsBuyboxState } from './State/LsFirstBuyboxState';
import { ILsFirstBuyboxAddToCartViewProps } from './ViewProps/LsFirstIBuyboxAddToCartViewProps';
import { RecipeView } from '../../restaurants.online/Platform/Products/Views/RecipeView';

const BuyboxView: React.FC<ILsBuyboxViewProps> = props => {
    const {
        ModuleProps,
        MediaGalleryContainerProps,
        ProductInfoContainerProps,
        addToCart,
        addToOrderTemplate,
        addToWishlist,
        telemetryContent,
        configure,
        description,
        findInStore,
        quantity,
        price,
        title,
        rating,
        callbacks,
        state,
        resources,
        inventoryLabel,
        shopSimilarLook,
        keyInPrice,
        quantityLimitsMessages,
        max,
        shopSimilarDescription,
        unitOfMeasure,
        ingredients,
        predefinedComments,
        productModifierGroups,
        dealNodes,
        recipeOrDeal,
        dealDefaultSelections,
        lsCallbacks,
        lsAPILoaded
    } = props;

    return (
        // everything wrapped under the provider will have access to the buybox context
        <LsFirstBuyboxContext.Provider
            value={{ recipeOrDeal, dealDefaultSelections, lsErrorState: state.lsErrorState, lsCallbacks, lsAPILoaded }}
        >
            <Module {...ModuleProps}>
                <Node {...MediaGalleryContainerProps}>{props.mediaGallery}</Node>
                <Node {...ProductInfoContainerProps}>
                    {title}
                    {description}
                    <RecipeView>
                        {ingredients}
                        {productModifierGroups}
                        {predefinedComments}
                    </RecipeView>
                    {dealNodes}
                    {price}
                    {unitOfMeasure}
                    {rating}
                    {configure && _renderConfigure(configure)}
                    {keyInPrice && _renderKeyInPrice(keyInPrice)}
                    {quantity &&
                        _renderQuantity(quantity, callbacks, props, state, resources, quantityLimitsMessages, max, telemetryContent)}
                    {inventoryLabel}
                    {_renderCartAndActions(addToCart, addToOrderTemplate, addToWishlist)}
                    {findInStore && _renderFindInStore(findInStore)}
                    {_renderSocialShare(props.slots && props.slots.socialShare)}
                    {shopSimilarLook && _renderShopSimilarItem(shopSimilarLook)}
                    {shopSimilarDescription && _renderShopSimilarItem(shopSimilarDescription)}
                </Node>
            </Module>
        </LsFirstBuyboxContext.Provider>
    );
};

const _renderCartAndActions = (
    addToCart?: ILsFirstBuyboxAddToCartViewProps,
    addToOrderTemplate?: IBuyboxAddToOrderTemplateViewProps,
    addToWishlist?: IBuyboxAddToWishlistViewProps
): JSX.Element | undefined => {
    if (!addToCart && !addToWishlist) {
        return undefined;
    }

    return (
        <div className='product-add-to-cart'>
            {addToCart && addToCart.lsErrorBlock}
            {addToCart && addToCart.errorBlock}
            {addToOrderTemplate && addToOrderTemplate.errorBlock}
            {addToWishlist && addToWishlist.errorBlock}
            <div className='buttons'>
                {addToCart && addToCart.button}
                {addToOrderTemplate && addToOrderTemplate.button}
                {addToWishlist && addToWishlist.button}
            </div>
        </div>
    );
};

const _renderConfigure = (configure: IBuyboxProductConfigureViewProps): JSX.Element => {
    const { ContainerProps, dropdowns } = configure;

    return <Node {...ContainerProps}>{dropdowns.map(_renderConfigureDropdown)}</Node>;
};

const _renderConfigureDropdown = (dropdown: IBuyboxProductConfigureDropdownViewProps): JSX.Element => {
    const { ContainerProps, LabelContainerProps, heading, errors, select } = dropdown;

    return (
        <Node {...ContainerProps}>
            <Node {...LabelContainerProps}>
                {heading}
                {errors}
            </Node>
            {select}
        </Node>
    );
};

const _renderFindInStore = (findInStore: IBuyboxFindInStoreViewProps): JSX.Element => {
    const { ContainerProps, storeSelector, heading, description, errors, button, modal, productPickupOptionList } = findInStore;

    return (
        <Node {...ContainerProps}>
            {storeSelector}
            {heading}
            {productPickupOptionList}
            {description}
            {errors}
            {button}
            {modal}
        </Node>
    );
};

/**
 * On Change function.
 * @param callbacks -Callbacks.
 * @returns Update quantity.
 */
const onChangeHandler = (callbacks: IBuyboxCallbacks) => (newValue: number): boolean => {
    if (callbacks.updateQuantity) {
        return callbacks.updateQuantity(newValue);
    }
    return true;
};

const _renderQuantity = (
    quantityComponent: IBuyboxProductQuantityViewProps,
    callbacks: IBuyboxCallbacks,
    props: ILsfirstBuyboxProps<ILsfirstBuyboxData>,
    state: ILsBuyboxState,
    extentionResources: ILsfirstBuyboxResources,
    quantityLimitsMessages: React.ReactNode,
    max: number | undefined,
    telemetryContent?: ITelemetryContent
): JSX.Element => {
    const { ContainerProps, LabelContainerProps, heading, errors } = quantityComponent;

    const { resources } = props;

    const { quantity } = state;

    return (
        <Node {...ContainerProps}>
            <Node {...LabelContainerProps}>
                {heading}
                {errors}
            </Node>

            <IncrementalQuantity
                id='ms-buybox__product-quantity-input'
                max={max}
                currentCount={quantity}
                onChange={onChangeHandler(callbacks)}
                inputQuantityAriaLabel={resources.inputQuantityAriaLabel}
                decrementButtonAriaLabel={extentionResources.decrementButtonAriaLabel}
                incrementButtonAriaLabel={extentionResources.incrementButtonAriaLabel}
                minQuantityText={extentionResources.minQuantityText}
                maxQuantityText={extentionResources.maxQuantityText}
                telemetryContent={telemetryContent}
            />
            {quantityLimitsMessages}
        </Node>
    );
};

const _renderKeyInPrice = (keyInPrice: IBuyboxKeyInPriceViewProps): JSX.Element => {
    const { ContainerProps, LabelContainerProps, heading, input } = keyInPrice;

    return (
        <Node {...ContainerProps}>
            <Node {...LabelContainerProps}>{heading}</Node>
            {input}
        </Node>
    );
};

const _renderShopSimilarItem = (shopSimilarItem: IBuyboxShopSimilarLookViewProps): JSX.Element => {
    const { ContainerProps, errors, input } = shopSimilarItem;

    return (
        <Node {...ContainerProps}>
            {errors}
            {input}
        </Node>
    );
};

const _renderSocialShare = (socialShare: React.ReactNode[]): JSX.Element | undefined => {
    if (!socialShare || socialShare.length === 0) {
        return undefined;
    }

    return <>{socialShare[0]}</>;
};

export default BuyboxView;
