import React from 'react';
import { ILsDealDefaultSelections } from '..';
import { Deal } from '../../../restaurants.online/Platform/Products/DataModel/Deals/Deal';
import { Recipe } from '../../../restaurants.online/Platform/Products/DataModel/Recipe';
import { ILsFirstBuyboxCallbacks } from '../ILsFirstBuyboxCallbacks';
import { ILsErrorState } from '../State/LsFirstBuyboxState';

interface ILsFirstBuyboxContext {
    recipeOrDeal?: Recipe | Deal;
    dealDefaultSelections?: ILsDealDefaultSelections;
    lsErrorState: ILsErrorState;
    lsCallbacks?: ILsFirstBuyboxCallbacks;
    lsAPILoaded: boolean;
}

export const LsFirstBuyboxContext = React.createContext<ILsFirstBuyboxContext>({
    recipeOrDeal: undefined,
    dealDefaultSelections: undefined,
    lsErrorState: [],
    lsCallbacks: undefined,
    lsAPILoaded: false
});
