
  // tslint:disable
  import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
  import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';  
  import { jsonLightReadStringPropertyValue } from '@msdyn365-commerce/retail-proxy/dist/externals';
  
  
    /**
    * LsInfoCodeActions entity interface.
    */
    export interface ILsInfoCodeActions {
    InfoCodeId: string;
    OperationId: number;
    PosOperationParameter?: string;
    RecId: number;
    EntityName?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
    }
  
    /**
    * DeliveryModeInfo entity interface.
    */
    export interface IDeliveryModeInfo {
    DefaultDeliveryMode: number;
    DeliveryModeCode: string;
    DeliveryMode?: string;
    DeliveryModeProfileId?: string;
    QsrDestinationId: number;
    TerminalId: string;
    ExtensionProperties?: Entities.CommerceProperty[];
    }
  
    /**
    * ProductDealsInfo entity interface.
    */
    export interface IProductDealsInfo {
    BomItemId?: string;
    HospitalityType: number;
    InventTableItemId: string;
    Name?: string;
    Product: number;
    ExtensionProperties?: Entities.CommerceProperty[];
    }
  
    /**
    * DiningTableInfo entity interface.
    */
    export interface IDiningTableInfo {
    LockedByRegisterId?: string;
    PermittedTransitions?: string;
    StoreNumber?: string;
    TableId: string;
    TableState?: string;
    TableStatusWorkflowVersion?: string;
    TransactionGroupId?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
    }
  
    /**
    * RestaurantLayoutProfileInfo entity interface.
    */
    export interface IRestaurantLayoutProfileInfo {
    LayoutId: string;
    Description?: string;
    PictureId: number;
    Design?: string;
    StoreNumber?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
    }
  
    /**
    * ReplaceCartLineWithProductResponse entity interface.
    */
    export interface IReplaceCartLineWithProductResponse {
    }
  
    /**
    * LsCartLine entity interface.
    */
    export interface ILsCartLine {
    CartId?: string;
    Comments?: ILsComments;
    CustomerId?: string;
    DealLines?: ILshDealLine[];
    Description?: string;
    Ingredients?: ILshIngredient[];
    IsPriceKeyedIn: boolean;
    IsPriceOverridden: boolean;
    IsVoided: boolean;
    ItemId?: string;
    LineId?: string;
    LineNumber: number;
    LshCartLineGroupId?: string;
    LstSeatNumber: number;
    OriginalLineId?: string;
    OriginalPrice: number;
    PredefinedComments?: ILsPredefinedCommentGroup[];
    Price: number;
    ProductId: number;
    ProductModifierGroups?: ILshProductModifierGroup[];
    Quantity: number;
    SalesDate?: Date;
    UnitOfMeasureDecimalPrecision: number;
    }
  
    /**
    * LsCart entity interface.
    */
    export interface ILsCart {
    CartLines?: ILsCartLine[];
    Comment?: string;
    CartId?: string;
    IsSuspended: boolean;
    LshBillNumber: number;
    LshKitchenCustomerCallOut?: string;
    LshKitchenOrderId?: string;
    LshDeliveryMode?: ILsDeliveryMode;
    LshKitchenOrderStatus?: string;
    LshKitchenOrderTicketId?: string;
    LshKitchenOrderToSend?: string;
    LshKitchenOrderTrackingNumber: number;
    LshKitchenOrderTransactionGroupId?: string;
    LshKitchenRushOrder?: string;
    LshLockedByRegisterId?: string;
    LshTransactionComment?: string;
    LshTransactionGroupId?: string;
    LstActiveSeat: number;
    LstGuestCount: number;
    LstTableAssigned: number;
    Store?: string;
    Terminal?: string;
    }
  
    /**
    * PreEndTransactionTriggerResponse entity interface.
    */
    export interface IPreEndTransactionTriggerResponse {
    }
  
    /**
    * PreVoidTransactionTriggerResponse entity interface.
    */
    export interface IPreVoidTransactionTriggerResponse {
    }
  
    /**
    * PostSetQuantityTriggerResponse entity interface.
    */
    export interface IPostSetQuantityTriggerResponse {
    }
  
    /**
    * HospitalityRetailTransaction entity interface.
    */
    export interface IHospitalityRetailTransaction {
    RetailTransactionProperties?: IRetailTransaction;
    CustomerCallout?: string;
    TransactionLines?: IHospitalityRetailTransactionLine[];
    }
  
    /**
    * RetailTransaction entity interface.
    */
    export interface IRetailTransaction {
    Channel: number;
    DataAreaId?: string;
    Store?: string;
    TerminalId?: string;
    TransactionId?: string;
    }
  
    /**
    * HospitalityRetailTransactionLine entity interface.
    */
    export interface IHospitalityRetailTransactionLine {
    BomId?: string;
    BomVersionItemId?: string;
    HospitalityType: string;
    RetailTransactionProperties?: IRetailTransaction;
    RetailTransactionLineProperties?: IRetailTransactionLine;
    TransactionDealLines?: IHospitalityRetailTransactionDealLine[];
    TransactionPredefinedComments?: IHospitalityRetailTransactionPredefinedComment[];
    TransactionRecipeLines?: IHospitalityRetailTransactionRecipeLine[];
    }
  
    /**
    * RetailTransactionLine entity interface.
    */
    export interface IRetailTransactionLine {
    SalesTransactionLineNumber: number;
    }
  
    /**
    * HospitalityRetailTransactionDealLine entity interface.
    */
    export interface IHospitalityRetailTransactionDealLine {
    DealLineNumber: number;
    BomId?: string;
    BomVersionItemId?: string;
    ItemId?: string;
    Quantity: number;
    RetailTransactionProperties?: IRetailTransaction;
    RetailTransactionLineProperties?: IRetailTransactionLine;
    TransactionPredefinedComments?: IHospitalityRetailTransactionPredefinedComment[];
    TransactionDealLineRecipeLines?: IHospitalityRetailTransactionRecipeLine[];
    }
  
    /**
    * HospitalityRetailTransactionPredefinedComment entity interface.
    */
    export interface IHospitalityRetailTransactionPredefinedComment {
    DealLineNumber: number;
    PredefinedCommentsCommentLineNumber: number;
    PredefinedComment?: string;
    RetailTransactionProperties?: IRetailTransaction;
    RetailTransactionLineProperties?: IRetailTransactionLine;
    }
  
    /**
    * HospitalityRetailTransactionRecipeLine entity interface.
    */
    export interface IHospitalityRetailTransactionRecipeLine {
    BomId?: string;
    BomLineNumber: number;
    BomVersionItemId?: string;
    DealLineNumber: number;
    ModifiedStatus: string;
    HeavyLight: string;
    IsModifier: boolean;
    ItemId?: string;
    LeftRight: string;
    OriginalQuantity: number;
    Quantity: number;
    RetailTransactionProperties?: IRetailTransaction;
    RetailTransactionLineProperties?: IRetailTransactionLine;
    }
  
    /**
    * OpenModifiersViewResponse entity interface.
    */
    export interface IOpenModifiersViewResponse {
    CartContainsSplitByXItems?: boolean;
    OpenModifiersView?: boolean;
    }
  
    /**
    * SaveDiningTableInfoResponse entity interface.
    */
    export interface ISaveDiningTableInfoResponse {
    PermittedTransitions?: string[];
    NewState?: string;
    }
  
    /**
    * AssignTableToTransactionGroupResponse entity interface.
    */
    export interface IAssignTableToTransactionGroupResponse {
    CartId?: string;
    TransactionGroupId?: string;
    }
  
    /**
    * ForceReleaseTableResponse entity interface.
    */
    export interface IForceReleaseTableResponse {
    PermittedTransitions?: string[];
    NewState?: string;
    }
  
    /**
    * UpdateTransactionGroupLockResponse entity interface.
    */
    export interface IUpdateTransactionGroupLockResponse {
    }
  
    /**
    * KitchenTerminalConfiguration entity interface.
    */
    export interface IKitchenTerminalConfiguration {
    SendToKitchen: number;
    TerminalId?: string;
    }
  
    /**
    * LsComments entity interface.
    */
    export interface ILsComments {
    CommentStrings?: string[];
    }
  
    /**
    * LshDealLine entity interface.
    */
    export interface ILshDealLine {
    BomId?: string;
    BomVersionItemId?: string;
    Comments?: ILsComments;
    DealModifierGroups?: ILshDealModifierGroup[];
    DealModifierToSwapWith?: ILshDealModifier;
    HideOnPos: boolean;
    Ingredients?: ILshIngredient[];
    ItemId?: string;
    Name?: string;
    PredefinedComments?: ILsPredefinedCommentGroup[];
    Price: number;
    ProductModifierGroups?: ILshProductModifierGroup[];
    Quantity: number;
    ProductId: number;
    }
  
    /**
    * LshDealModifierGroup entity interface.
    */
    export interface ILshDealModifierGroup {
    Caption?: string;
    DealModifiers?: ILshDealModifier[];
    ModifierGroupId?: string;
    }
  
    /**
    * LshDealModifier entity interface.
    */
    export interface ILshDealModifier {
    BomVersionItemId?: string;
    Ingredients?: ILshIngredient[];
    ItemName?: string;
    ModifierItemId?: string;
    Price: number;
    ProductModifierGroups?: ILshProductModifierGroup[];
    }
  
    /**
    * LshIngredient entity interface.
    */
    export interface ILshIngredient {
    BomId?: string;
    BomMaxQuantity: number;
    BomMinQuantity: number;
    BomVersionItemId?: string;
    Comment?: string;
    HideOnPos: boolean;
    HeavyLight: boolean;
    ItemId?: string;
    ItemPartType: string;
    Name?: string;
    OriginalQuantity: number;
    Price: number;
    Quantity: number;
    ReducePrice: boolean;
    }
  
    /**
    * LshProductModifierGroup entity interface.
    */
    export interface ILshProductModifierGroup {
    AdditionalModifiersNeeded: boolean;
    Caption?: string;
    ModifierGroupId?: string;
    MaxQuantity: number;
    MinQuantity: number;
    Modifiers?: ILshProductModifier[];
    }
  
    /**
    * LshProductModifier entity interface.
    */
    export interface ILshProductModifier {
    BomVersionItemId?: string;
    Comment?: string;
    ItemId?: string;
    ItemPartType: string;
    Name?: string;
    Price: number;
    Quantity: number;
    }
  
    /**
    * LsPredefinedCommentGroup entity interface.
    */
    export interface ILsPredefinedCommentGroup {
    Description?: string;
    GroupId?: string;
    ItemId?: string;
    MaxSelection: number;
    MinSelection: number;
    PredefinedComments?: ILsPredefinedComment[];
    }
  
    /**
    * LsPredefinedComment entity interface.
    */
    export interface ILsPredefinedComment {
    Comment?: string;
    }
  
    /**
    * LsDeliveryMode entity interface.
    */
    export interface ILsDeliveryMode {
    DeliveryModeId?: string;
    DeliveryModeDescription?: string;
    DeliveryModeProfileId?: string;
    QsrDestinationId: number;
    }
  
  /**
   * LsInfoCodeActions entity class.
   */
  export class LsInfoCodeActionsExtensionClass implements ILsInfoCodeActions {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InfoCodeId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OperationId: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PosOperationParameter: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RecId: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public EntityName: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InfoCodeId = odataObject.InfoCodeId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.OperationId = odataObject.OperationId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PosOperationParameter = odataObject.PosOperationParameter;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RecId = (odataObject.RecId) ? parseInt(odataObject.RecId, 10) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.EntityName = odataObject.EntityName;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * DeliveryModeInfo entity class.
   */
  export class DeliveryModeInfoExtensionClass implements IDeliveryModeInfo {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DefaultDeliveryMode: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DeliveryModeCode: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DeliveryMode: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DeliveryModeProfileId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public QsrDestinationId: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TerminalId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DefaultDeliveryMode = odataObject.DefaultDeliveryMode;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DeliveryModeCode = odataObject.DeliveryModeCode;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DeliveryMode = odataObject.DeliveryMode;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DeliveryModeProfileId = odataObject.DeliveryModeProfileId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.QsrDestinationId = odataObject.QsrDestinationId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TerminalId = odataObject.TerminalId;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * ProductDealsInfo entity class.
   */
  export class ProductDealsInfoExtensionClass implements IProductDealsInfo {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HospitalityType: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InventTableItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Name: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Product: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomItemId = odataObject.BomItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.HospitalityType = odataObject.HospitalityType;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InventTableItemId = odataObject.InventTableItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Name = odataObject.Name;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Product = (odataObject.Product) ? parseInt(odataObject.Product, 10) : 0;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * DiningTableInfo entity class.
   */
  export class DiningTableInfoExtensionClass implements IDiningTableInfo {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LockedByRegisterId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PermittedTransitions: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StoreNumber: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TableId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TableState: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TableStatusWorkflowVersion: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionGroupId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LockedByRegisterId = odataObject.LockedByRegisterId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PermittedTransitions = odataObject.PermittedTransitions;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.StoreNumber = odataObject.StoreNumber;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TableId = odataObject.TableId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TableState = odataObject.TableState;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TableStatusWorkflowVersion = odataObject.TableStatusWorkflowVersion;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionGroupId = odataObject.TransactionGroupId;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * RestaurantLayoutProfileInfo entity class.
   */
  export class RestaurantLayoutProfileInfoExtensionClass implements IRestaurantLayoutProfileInfo {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LayoutId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Description: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PictureId: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Design: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StoreNumber: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LayoutId = odataObject.LayoutId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Description = odataObject.Description;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PictureId = odataObject.PictureId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Design = odataObject.Design;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.StoreNumber = odataObject.StoreNumber;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * ReplaceCartLineWithProductResponse entity class.
   */
  export class ReplaceCartLineWithProductResponseExtensionClass implements IReplaceCartLineWithProductResponse {
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
  }

  /**
   * LsCartLine entity class.
   */
  export class LsCartLineExtensionClass implements ILsCartLine {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Comments: 
            ILsComments;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CustomerId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DealLines: 
            ILshDealLine[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Description: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Ingredients: 
            ILshIngredient[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsPriceKeyedIn: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsPriceOverridden: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsVoided: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LineId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LineNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshCartLineGroupId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LstSeatNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OriginalLineId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OriginalPrice: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PredefinedComments: 
            ILsPredefinedCommentGroup[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Price: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProductId: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProductModifierGroups: 
            ILshProductModifierGroup[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SalesDate: Date;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public UnitOfMeasureDecimalPrecision: number;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CartId = odataObject.CartId;
              
        if (odataObject.Comments === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Comments = undefined;
        } else if (odataObject.Comments['@odata.type'] == null) {
          this.Comments = new LsCommentsExtensionClass(odataObject.Comments);
        } else {
          var className: string = odataObject.Comments['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Comments = new EntityClasses[className](odataObject.Comments)
          }
        }

      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CustomerId = odataObject.CustomerId;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DealLines = undefined;
        if (odataObject.DealLines) {
          this.DealLines = [];
          for (var i = 0; i < odataObject.DealLines.length; i++) {
            if (odataObject.DealLines[i]) {
        if (odataObject.DealLines[i]['@odata.type']) {
          var className: string = odataObject.DealLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.DealLines[i] = new EntityClasses[className](odataObject.DealLines[i])
          }
        } else {
          this.DealLines[i] = new LshDealLineExtensionClass(odataObject.DealLines[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.DealLines[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Description = odataObject.Description;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Ingredients = undefined;
        if (odataObject.Ingredients) {
          this.Ingredients = [];
          for (var i = 0; i < odataObject.Ingredients.length; i++) {
            if (odataObject.Ingredients[i]) {
        if (odataObject.Ingredients[i]['@odata.type']) {
          var className: string = odataObject.Ingredients[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Ingredients[i] = new EntityClasses[className](odataObject.Ingredients[i])
          }
        } else {
          this.Ingredients[i] = new LshIngredientExtensionClass(odataObject.Ingredients[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.Ingredients[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsPriceKeyedIn = odataObject.IsPriceKeyedIn;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsPriceOverridden = odataObject.IsPriceOverridden;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsVoided = odataObject.IsVoided;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemId = odataObject.ItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LineId = odataObject.LineId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LineNumber = (odataObject.LineNumber) ? parseFloat(odataObject.LineNumber) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshCartLineGroupId = odataObject.LshCartLineGroupId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LstSeatNumber = odataObject.LstSeatNumber;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.OriginalLineId = odataObject.OriginalLineId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.OriginalPrice = (odataObject.OriginalPrice) ? parseFloat(odataObject.OriginalPrice) : 0;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PredefinedComments = undefined;
        if (odataObject.PredefinedComments) {
          this.PredefinedComments = [];
          for (var i = 0; i < odataObject.PredefinedComments.length; i++) {
            if (odataObject.PredefinedComments[i]) {
        if (odataObject.PredefinedComments[i]['@odata.type']) {
          var className: string = odataObject.PredefinedComments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PredefinedComments[i] = new EntityClasses[className](odataObject.PredefinedComments[i])
          }
        } else {
          this.PredefinedComments[i] = new LsPredefinedCommentGroupExtensionClass(odataObject.PredefinedComments[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.PredefinedComments[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Price = (odataObject.Price) ? parseFloat(odataObject.Price) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ProductId = (odataObject.ProductId) ? parseInt(odataObject.ProductId, 10) : 0;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductModifierGroups = undefined;
        if (odataObject.ProductModifierGroups) {
          this.ProductModifierGroups = [];
          for (var i = 0; i < odataObject.ProductModifierGroups.length; i++) {
            if (odataObject.ProductModifierGroups[i]) {
        if (odataObject.ProductModifierGroups[i]['@odata.type']) {
          var className: string = odataObject.ProductModifierGroups[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProductModifierGroups[i] = new EntityClasses[className](odataObject.ProductModifierGroups[i])
          }
        } else {
          this.ProductModifierGroups[i] = new LshProductModifierGroupExtensionClass(odataObject.ProductModifierGroups[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ProductModifierGroups[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SalesDate = (odataObject.SalesDate instanceof Date) ? odataObject.SalesDate
                : (odataObject.SalesDate) ? jsonLightReadStringPropertyValue(odataObject.SalesDate, 'Edm.DateTimeOffset', false)  : undefined;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.UnitOfMeasureDecimalPrecision = odataObject.UnitOfMeasureDecimalPrecision;
              
      }
  }

  /**
   * LsCart entity class.
   */
  export class LsCartExtensionClass implements ILsCart {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartLines: 
            ILsCartLine[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Comment: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsSuspended: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshBillNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenCustomerCallOut: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenOrderId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshDeliveryMode: 
            ILsDeliveryMode;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenOrderStatus: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenOrderTicketId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenOrderToSend: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenOrderTrackingNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenOrderTransactionGroupId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshKitchenRushOrder: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshLockedByRegisterId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshTransactionComment: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LshTransactionGroupId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LstActiveSeat: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LstGuestCount: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LstTableAssigned: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Store: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Terminal: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CartLines = undefined;
        if (odataObject.CartLines) {
          this.CartLines = [];
          for (var i = 0; i < odataObject.CartLines.length; i++) {
            if (odataObject.CartLines[i]) {
        if (odataObject.CartLines[i]['@odata.type']) {
          var className: string = odataObject.CartLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.CartLines[i] = new EntityClasses[className](odataObject.CartLines[i])
          }
        } else {
          this.CartLines[i] = new LsCartLineExtensionClass(odataObject.CartLines[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.CartLines[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Comment = odataObject.Comment;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CartId = odataObject.CartId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsSuspended = odataObject.IsSuspended;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshBillNumber = odataObject.LshBillNumber;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenCustomerCallOut = odataObject.LshKitchenCustomerCallOut;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenOrderId = odataObject.LshKitchenOrderId;
              
        if (odataObject.LshDeliveryMode === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.LshDeliveryMode = undefined;
        } else if (odataObject.LshDeliveryMode['@odata.type'] == null) {
          this.LshDeliveryMode = new LsDeliveryModeExtensionClass(odataObject.LshDeliveryMode);
        } else {
          var className: string = odataObject.LshDeliveryMode['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.LshDeliveryMode = new EntityClasses[className](odataObject.LshDeliveryMode)
          }
        }

      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenOrderStatus = odataObject.LshKitchenOrderStatus;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenOrderTicketId = odataObject.LshKitchenOrderTicketId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenOrderToSend = odataObject.LshKitchenOrderToSend;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenOrderTrackingNumber = (odataObject.LshKitchenOrderTrackingNumber) ? parseInt(odataObject.LshKitchenOrderTrackingNumber, 10) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenOrderTransactionGroupId = odataObject.LshKitchenOrderTransactionGroupId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshKitchenRushOrder = odataObject.LshKitchenRushOrder;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshLockedByRegisterId = odataObject.LshLockedByRegisterId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshTransactionComment = odataObject.LshTransactionComment;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LshTransactionGroupId = odataObject.LshTransactionGroupId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LstActiveSeat = odataObject.LstActiveSeat;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LstGuestCount = odataObject.LstGuestCount;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LstTableAssigned = odataObject.LstTableAssigned;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Store = odataObject.Store;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Terminal = odataObject.Terminal;
              
      }
  }

  /**
   * PreEndTransactionTriggerResponse entity class.
   */
  export class PreEndTransactionTriggerResponseExtensionClass implements IPreEndTransactionTriggerResponse {
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
  }

  /**
   * PreVoidTransactionTriggerResponse entity class.
   */
  export class PreVoidTransactionTriggerResponseExtensionClass implements IPreVoidTransactionTriggerResponse {
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
  }

  /**
   * PostSetQuantityTriggerResponse entity class.
   */
  export class PostSetQuantityTriggerResponseExtensionClass implements IPostSetQuantityTriggerResponse {
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
  }

  /**
   * HospitalityRetailTransaction entity class.
   */
  export class HospitalityRetailTransactionExtensionClass implements IHospitalityRetailTransaction {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionProperties: 
            IRetailTransaction;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CustomerCallout: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionLines: 
            IHospitalityRetailTransactionLine[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.RetailTransactionProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionProperties = undefined;
        } else if (odataObject.RetailTransactionProperties['@odata.type'] == null) {
          this.RetailTransactionProperties = new RetailTransactionExtensionClass(odataObject.RetailTransactionProperties);
        } else {
          var className: string = odataObject.RetailTransactionProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionProperties = new EntityClasses[className](odataObject.RetailTransactionProperties)
          }
        }

      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CustomerCallout = odataObject.CustomerCallout;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TransactionLines = undefined;
        if (odataObject.TransactionLines) {
          this.TransactionLines = [];
          for (var i = 0; i < odataObject.TransactionLines.length; i++) {
            if (odataObject.TransactionLines[i]) {
        if (odataObject.TransactionLines[i]['@odata.type']) {
          var className: string = odataObject.TransactionLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.TransactionLines[i] = new EntityClasses[className](odataObject.TransactionLines[i])
          }
        } else {
          this.TransactionLines[i] = new HospitalityRetailTransactionLineExtensionClass(odataObject.TransactionLines[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.TransactionLines[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * RetailTransaction entity class.
   */
  export class RetailTransactionExtensionClass implements IRetailTransaction {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Channel: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DataAreaId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Store: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TerminalId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionId: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Channel = (odataObject.Channel) ? parseInt(odataObject.Channel, 10) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DataAreaId = odataObject.DataAreaId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Store = odataObject.Store;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TerminalId = odataObject.TerminalId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionId = odataObject.TransactionId;
              
      }
  }

  /**
   * HospitalityRetailTransactionLine entity class.
   */
  export class HospitalityRetailTransactionLineExtensionClass implements IHospitalityRetailTransactionLine {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomVersionItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HospitalityType: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionProperties: 
            IRetailTransaction;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionLineProperties: 
            IRetailTransactionLine;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionDealLines: 
            IHospitalityRetailTransactionDealLine[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionPredefinedComments: 
            IHospitalityRetailTransactionPredefinedComment[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionRecipeLines: 
            IHospitalityRetailTransactionRecipeLine[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomId = odataObject.BomId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomVersionItemId = odataObject.BomVersionItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.HospitalityType = odataObject.HospitalityType;
              
        if (odataObject.RetailTransactionProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionProperties = undefined;
        } else if (odataObject.RetailTransactionProperties['@odata.type'] == null) {
          this.RetailTransactionProperties = new RetailTransactionExtensionClass(odataObject.RetailTransactionProperties);
        } else {
          var className: string = odataObject.RetailTransactionProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionProperties = new EntityClasses[className](odataObject.RetailTransactionProperties)
          }
        }

      
        if (odataObject.RetailTransactionLineProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionLineProperties = undefined;
        } else if (odataObject.RetailTransactionLineProperties['@odata.type'] == null) {
          this.RetailTransactionLineProperties = new RetailTransactionLineExtensionClass(odataObject.RetailTransactionLineProperties);
        } else {
          var className: string = odataObject.RetailTransactionLineProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionLineProperties = new EntityClasses[className](odataObject.RetailTransactionLineProperties)
          }
        }

      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TransactionDealLines = undefined;
        if (odataObject.TransactionDealLines) {
          this.TransactionDealLines = [];
          for (var i = 0; i < odataObject.TransactionDealLines.length; i++) {
            if (odataObject.TransactionDealLines[i]) {
        if (odataObject.TransactionDealLines[i]['@odata.type']) {
          var className: string = odataObject.TransactionDealLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.TransactionDealLines[i] = new EntityClasses[className](odataObject.TransactionDealLines[i])
          }
        } else {
          this.TransactionDealLines[i] = new HospitalityRetailTransactionDealLineExtensionClass(odataObject.TransactionDealLines[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.TransactionDealLines[i] = undefined;
            }
          }
        }
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TransactionPredefinedComments = undefined;
        if (odataObject.TransactionPredefinedComments) {
          this.TransactionPredefinedComments = [];
          for (var i = 0; i < odataObject.TransactionPredefinedComments.length; i++) {
            if (odataObject.TransactionPredefinedComments[i]) {
        if (odataObject.TransactionPredefinedComments[i]['@odata.type']) {
          var className: string = odataObject.TransactionPredefinedComments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.TransactionPredefinedComments[i] = new EntityClasses[className](odataObject.TransactionPredefinedComments[i])
          }
        } else {
          this.TransactionPredefinedComments[i] = new HospitalityRetailTransactionPredefinedCommentExtensionClass(odataObject.TransactionPredefinedComments[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.TransactionPredefinedComments[i] = undefined;
            }
          }
        }
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TransactionRecipeLines = undefined;
        if (odataObject.TransactionRecipeLines) {
          this.TransactionRecipeLines = [];
          for (var i = 0; i < odataObject.TransactionRecipeLines.length; i++) {
            if (odataObject.TransactionRecipeLines[i]) {
        if (odataObject.TransactionRecipeLines[i]['@odata.type']) {
          var className: string = odataObject.TransactionRecipeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.TransactionRecipeLines[i] = new EntityClasses[className](odataObject.TransactionRecipeLines[i])
          }
        } else {
          this.TransactionRecipeLines[i] = new HospitalityRetailTransactionRecipeLineExtensionClass(odataObject.TransactionRecipeLines[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.TransactionRecipeLines[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * RetailTransactionLine entity class.
   */
  export class RetailTransactionLineExtensionClass implements IRetailTransactionLine {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SalesTransactionLineNumber: number;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SalesTransactionLineNumber = (odataObject.SalesTransactionLineNumber) ? parseFloat(odataObject.SalesTransactionLineNumber) : 0;
              
      }
  }

  /**
   * HospitalityRetailTransactionDealLine entity class.
   */
  export class HospitalityRetailTransactionDealLineExtensionClass implements IHospitalityRetailTransactionDealLine {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DealLineNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomVersionItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionProperties: 
            IRetailTransaction;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionLineProperties: 
            IRetailTransactionLine;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionPredefinedComments: 
            IHospitalityRetailTransactionPredefinedComment[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionDealLineRecipeLines: 
            IHospitalityRetailTransactionRecipeLine[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DealLineNumber = (odataObject.DealLineNumber) ? parseFloat(odataObject.DealLineNumber) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomId = odataObject.BomId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomVersionItemId = odataObject.BomVersionItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemId = odataObject.ItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;
              
        if (odataObject.RetailTransactionProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionProperties = undefined;
        } else if (odataObject.RetailTransactionProperties['@odata.type'] == null) {
          this.RetailTransactionProperties = new RetailTransactionExtensionClass(odataObject.RetailTransactionProperties);
        } else {
          var className: string = odataObject.RetailTransactionProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionProperties = new EntityClasses[className](odataObject.RetailTransactionProperties)
          }
        }

      
        if (odataObject.RetailTransactionLineProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionLineProperties = undefined;
        } else if (odataObject.RetailTransactionLineProperties['@odata.type'] == null) {
          this.RetailTransactionLineProperties = new RetailTransactionLineExtensionClass(odataObject.RetailTransactionLineProperties);
        } else {
          var className: string = odataObject.RetailTransactionLineProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionLineProperties = new EntityClasses[className](odataObject.RetailTransactionLineProperties)
          }
        }

      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TransactionPredefinedComments = undefined;
        if (odataObject.TransactionPredefinedComments) {
          this.TransactionPredefinedComments = [];
          for (var i = 0; i < odataObject.TransactionPredefinedComments.length; i++) {
            if (odataObject.TransactionPredefinedComments[i]) {
        if (odataObject.TransactionPredefinedComments[i]['@odata.type']) {
          var className: string = odataObject.TransactionPredefinedComments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.TransactionPredefinedComments[i] = new EntityClasses[className](odataObject.TransactionPredefinedComments[i])
          }
        } else {
          this.TransactionPredefinedComments[i] = new HospitalityRetailTransactionPredefinedCommentExtensionClass(odataObject.TransactionPredefinedComments[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.TransactionPredefinedComments[i] = undefined;
            }
          }
        }
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TransactionDealLineRecipeLines = undefined;
        if (odataObject.TransactionDealLineRecipeLines) {
          this.TransactionDealLineRecipeLines = [];
          for (var i = 0; i < odataObject.TransactionDealLineRecipeLines.length; i++) {
            if (odataObject.TransactionDealLineRecipeLines[i]) {
        if (odataObject.TransactionDealLineRecipeLines[i]['@odata.type']) {
          var className: string = odataObject.TransactionDealLineRecipeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.TransactionDealLineRecipeLines[i] = new EntityClasses[className](odataObject.TransactionDealLineRecipeLines[i])
          }
        } else {
          this.TransactionDealLineRecipeLines[i] = new HospitalityRetailTransactionRecipeLineExtensionClass(odataObject.TransactionDealLineRecipeLines[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.TransactionDealLineRecipeLines[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * HospitalityRetailTransactionPredefinedComment entity class.
   */
  export class HospitalityRetailTransactionPredefinedCommentExtensionClass implements IHospitalityRetailTransactionPredefinedComment {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DealLineNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PredefinedCommentsCommentLineNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PredefinedComment: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionProperties: 
            IRetailTransaction;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionLineProperties: 
            IRetailTransactionLine;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DealLineNumber = (odataObject.DealLineNumber) ? parseFloat(odataObject.DealLineNumber) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PredefinedCommentsCommentLineNumber = (odataObject.PredefinedCommentsCommentLineNumber) ? parseFloat(odataObject.PredefinedCommentsCommentLineNumber) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PredefinedComment = odataObject.PredefinedComment;
              
        if (odataObject.RetailTransactionProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionProperties = undefined;
        } else if (odataObject.RetailTransactionProperties['@odata.type'] == null) {
          this.RetailTransactionProperties = new RetailTransactionExtensionClass(odataObject.RetailTransactionProperties);
        } else {
          var className: string = odataObject.RetailTransactionProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionProperties = new EntityClasses[className](odataObject.RetailTransactionProperties)
          }
        }

      
        if (odataObject.RetailTransactionLineProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionLineProperties = undefined;
        } else if (odataObject.RetailTransactionLineProperties['@odata.type'] == null) {
          this.RetailTransactionLineProperties = new RetailTransactionLineExtensionClass(odataObject.RetailTransactionLineProperties);
        } else {
          var className: string = odataObject.RetailTransactionLineProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionLineProperties = new EntityClasses[className](odataObject.RetailTransactionLineProperties)
          }
        }

      
      }
  }

  /**
   * HospitalityRetailTransactionRecipeLine entity class.
   */
  export class HospitalityRetailTransactionRecipeLineExtensionClass implements IHospitalityRetailTransactionRecipeLine {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomLineNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomVersionItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DealLineNumber: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ModifiedStatus: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HeavyLight: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsModifier: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeftRight: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OriginalQuantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionProperties: 
            IRetailTransaction;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailTransactionLineProperties: 
            IRetailTransactionLine;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomId = odataObject.BomId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomLineNumber = (odataObject.BomLineNumber) ? parseFloat(odataObject.BomLineNumber) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomVersionItemId = odataObject.BomVersionItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DealLineNumber = (odataObject.DealLineNumber) ? parseFloat(odataObject.DealLineNumber) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ModifiedStatus = odataObject.ModifiedStatus;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.HeavyLight = odataObject.HeavyLight;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsModifier = odataObject.IsModifier;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemId = odataObject.ItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeftRight = odataObject.LeftRight;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.OriginalQuantity = (odataObject.OriginalQuantity) ? parseFloat(odataObject.OriginalQuantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;
              
        if (odataObject.RetailTransactionProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionProperties = undefined;
        } else if (odataObject.RetailTransactionProperties['@odata.type'] == null) {
          this.RetailTransactionProperties = new RetailTransactionExtensionClass(odataObject.RetailTransactionProperties);
        } else {
          var className: string = odataObject.RetailTransactionProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionProperties = new EntityClasses[className](odataObject.RetailTransactionProperties)
          }
        }

      
        if (odataObject.RetailTransactionLineProperties === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.RetailTransactionLineProperties = undefined;
        } else if (odataObject.RetailTransactionLineProperties['@odata.type'] == null) {
          this.RetailTransactionLineProperties = new RetailTransactionLineExtensionClass(odataObject.RetailTransactionLineProperties);
        } else {
          var className: string = odataObject.RetailTransactionLineProperties['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailTransactionLineProperties = new EntityClasses[className](odataObject.RetailTransactionLineProperties)
          }
        }

      
      }
  }

  /**
   * OpenModifiersViewResponse entity class.
   */
  export class OpenModifiersViewResponseExtensionClass implements IOpenModifiersViewResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartContainsSplitByXItems: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OpenModifiersView: boolean;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CartContainsSplitByXItems = odataObject.CartContainsSplitByXItems;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.OpenModifiersView = odataObject.OpenModifiersView;
              
      }
  }

  /**
   * SaveDiningTableInfoResponse entity class.
   */
  export class SaveDiningTableInfoResponseExtensionClass implements ISaveDiningTableInfoResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PermittedTransitions: string[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NewState: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PermittedTransitions = undefined;
        if (odataObject.PermittedTransitions) {
          this.PermittedTransitions = [];
          for (var i = 0; i < odataObject.PermittedTransitions.length; i++) {
            if (odataObject.PermittedTransitions[i]) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PermittedTransitions[i] = 
                odataObject.PermittedTransitions[i];
              
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.PermittedTransitions[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NewState = odataObject.NewState;
              
      }
  }

  /**
   * AssignTableToTransactionGroupResponse entity class.
   */
  export class AssignTableToTransactionGroupResponseExtensionClass implements IAssignTableToTransactionGroupResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionGroupId: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CartId = odataObject.CartId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionGroupId = odataObject.TransactionGroupId;
              
      }
  }

  /**
   * ForceReleaseTableResponse entity class.
   */
  export class ForceReleaseTableResponseExtensionClass implements IForceReleaseTableResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PermittedTransitions: string[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NewState: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PermittedTransitions = undefined;
        if (odataObject.PermittedTransitions) {
          this.PermittedTransitions = [];
          for (var i = 0; i < odataObject.PermittedTransitions.length; i++) {
            if (odataObject.PermittedTransitions[i]) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PermittedTransitions[i] = 
                odataObject.PermittedTransitions[i];
              
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.PermittedTransitions[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NewState = odataObject.NewState;
              
      }
  }

  /**
   * UpdateTransactionGroupLockResponse entity class.
   */
  export class UpdateTransactionGroupLockResponseExtensionClass implements IUpdateTransactionGroupLockResponse {
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
  }

  /**
   * KitchenTerminalConfiguration entity class.
   */
  export class KitchenTerminalConfigurationExtensionClass implements IKitchenTerminalConfiguration {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SendToKitchen: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TerminalId: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SendToKitchen = odataObject.SendToKitchen;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TerminalId = odataObject.TerminalId;
              
      }
  }

  /**
   * LsComments entity class.
   */
  export class LsCommentsExtensionClass implements ILsComments {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CommentStrings: string[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CommentStrings = undefined;
        if (odataObject.CommentStrings) {
          this.CommentStrings = [];
          for (var i = 0; i < odataObject.CommentStrings.length; i++) {
            if (odataObject.CommentStrings[i]) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CommentStrings[i] = 
                odataObject.CommentStrings[i];
              
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.CommentStrings[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * LshDealLine entity class.
   */
  export class LshDealLineExtensionClass implements ILshDealLine {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomVersionItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Comments: 
            ILsComments;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DealModifierGroups: 
            ILshDealModifierGroup[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DealModifierToSwapWith: 
            ILshDealModifier;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HideOnPos: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Ingredients: 
            ILshIngredient[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Name: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PredefinedComments: 
            ILsPredefinedCommentGroup[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Price: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProductModifierGroups: 
            ILshProductModifierGroup[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProductId: number;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomId = odataObject.BomId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomVersionItemId = odataObject.BomVersionItemId;
              
        if (odataObject.Comments === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Comments = undefined;
        } else if (odataObject.Comments['@odata.type'] == null) {
          this.Comments = new LsCommentsExtensionClass(odataObject.Comments);
        } else {
          var className: string = odataObject.Comments['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Comments = new EntityClasses[className](odataObject.Comments)
          }
        }

      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DealModifierGroups = undefined;
        if (odataObject.DealModifierGroups) {
          this.DealModifierGroups = [];
          for (var i = 0; i < odataObject.DealModifierGroups.length; i++) {
            if (odataObject.DealModifierGroups[i]) {
        if (odataObject.DealModifierGroups[i]['@odata.type']) {
          var className: string = odataObject.DealModifierGroups[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.DealModifierGroups[i] = new EntityClasses[className](odataObject.DealModifierGroups[i])
          }
        } else {
          this.DealModifierGroups[i] = new LshDealModifierGroupExtensionClass(odataObject.DealModifierGroups[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.DealModifierGroups[i] = undefined;
            }
          }
        }
      
        if (odataObject.DealModifierToSwapWith === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.DealModifierToSwapWith = undefined;
        } else if (odataObject.DealModifierToSwapWith['@odata.type'] == null) {
          this.DealModifierToSwapWith = new LshDealModifierExtensionClass(odataObject.DealModifierToSwapWith);
        } else {
          var className: string = odataObject.DealModifierToSwapWith['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.DealModifierToSwapWith = new EntityClasses[className](odataObject.DealModifierToSwapWith)
          }
        }

      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.HideOnPos = odataObject.HideOnPos;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Ingredients = undefined;
        if (odataObject.Ingredients) {
          this.Ingredients = [];
          for (var i = 0; i < odataObject.Ingredients.length; i++) {
            if (odataObject.Ingredients[i]) {
        if (odataObject.Ingredients[i]['@odata.type']) {
          var className: string = odataObject.Ingredients[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Ingredients[i] = new EntityClasses[className](odataObject.Ingredients[i])
          }
        } else {
          this.Ingredients[i] = new LshIngredientExtensionClass(odataObject.Ingredients[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.Ingredients[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemId = odataObject.ItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Name = odataObject.Name;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PredefinedComments = undefined;
        if (odataObject.PredefinedComments) {
          this.PredefinedComments = [];
          for (var i = 0; i < odataObject.PredefinedComments.length; i++) {
            if (odataObject.PredefinedComments[i]) {
        if (odataObject.PredefinedComments[i]['@odata.type']) {
          var className: string = odataObject.PredefinedComments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PredefinedComments[i] = new EntityClasses[className](odataObject.PredefinedComments[i])
          }
        } else {
          this.PredefinedComments[i] = new LsPredefinedCommentGroupExtensionClass(odataObject.PredefinedComments[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.PredefinedComments[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Price = (odataObject.Price) ? parseFloat(odataObject.Price) : 0;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductModifierGroups = undefined;
        if (odataObject.ProductModifierGroups) {
          this.ProductModifierGroups = [];
          for (var i = 0; i < odataObject.ProductModifierGroups.length; i++) {
            if (odataObject.ProductModifierGroups[i]) {
        if (odataObject.ProductModifierGroups[i]['@odata.type']) {
          var className: string = odataObject.ProductModifierGroups[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProductModifierGroups[i] = new EntityClasses[className](odataObject.ProductModifierGroups[i])
          }
        } else {
          this.ProductModifierGroups[i] = new LshProductModifierGroupExtensionClass(odataObject.ProductModifierGroups[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ProductModifierGroups[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ProductId = (odataObject.ProductId) ? parseInt(odataObject.ProductId, 10) : 0;
              
      }
  }

  /**
   * LshDealModifierGroup entity class.
   */
  export class LshDealModifierGroupExtensionClass implements ILshDealModifierGroup {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Caption: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DealModifiers: 
            ILshDealModifier[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ModifierGroupId: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Caption = odataObject.Caption;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DealModifiers = undefined;
        if (odataObject.DealModifiers) {
          this.DealModifiers = [];
          for (var i = 0; i < odataObject.DealModifiers.length; i++) {
            if (odataObject.DealModifiers[i]) {
        if (odataObject.DealModifiers[i]['@odata.type']) {
          var className: string = odataObject.DealModifiers[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.DealModifiers[i] = new EntityClasses[className](odataObject.DealModifiers[i])
          }
        } else {
          this.DealModifiers[i] = new LshDealModifierExtensionClass(odataObject.DealModifiers[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.DealModifiers[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ModifierGroupId = odataObject.ModifierGroupId;
              
      }
  }

  /**
   * LshDealModifier entity class.
   */
  export class LshDealModifierExtensionClass implements ILshDealModifier {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomVersionItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Ingredients: 
            ILshIngredient[];
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemName: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ModifierItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Price: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProductModifierGroups: 
            ILshProductModifierGroup[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomVersionItemId = odataObject.BomVersionItemId;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Ingredients = undefined;
        if (odataObject.Ingredients) {
          this.Ingredients = [];
          for (var i = 0; i < odataObject.Ingredients.length; i++) {
            if (odataObject.Ingredients[i]) {
        if (odataObject.Ingredients[i]['@odata.type']) {
          var className: string = odataObject.Ingredients[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Ingredients[i] = new EntityClasses[className](odataObject.Ingredients[i])
          }
        } else {
          this.Ingredients[i] = new LshIngredientExtensionClass(odataObject.Ingredients[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.Ingredients[i] = undefined;
            }
          }
        }
      
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemName = odataObject.ItemName;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ModifierItemId = odataObject.ModifierItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Price = (odataObject.Price) ? parseFloat(odataObject.Price) : 0;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductModifierGroups = undefined;
        if (odataObject.ProductModifierGroups) {
          this.ProductModifierGroups = [];
          for (var i = 0; i < odataObject.ProductModifierGroups.length; i++) {
            if (odataObject.ProductModifierGroups[i]) {
        if (odataObject.ProductModifierGroups[i]['@odata.type']) {
          var className: string = odataObject.ProductModifierGroups[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProductModifierGroups[i] = new EntityClasses[className](odataObject.ProductModifierGroups[i])
          }
        } else {
          this.ProductModifierGroups[i] = new LshProductModifierGroupExtensionClass(odataObject.ProductModifierGroups[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ProductModifierGroups[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * LshIngredient entity class.
   */
  export class LshIngredientExtensionClass implements ILshIngredient {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomMaxQuantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomMinQuantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomVersionItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Comment: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HideOnPos: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HeavyLight: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemPartType: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Name: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OriginalQuantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Price: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ReducePrice: boolean;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomId = odataObject.BomId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomMaxQuantity = (odataObject.BomMaxQuantity) ? parseFloat(odataObject.BomMaxQuantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomMinQuantity = (odataObject.BomMinQuantity) ? parseFloat(odataObject.BomMinQuantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomVersionItemId = odataObject.BomVersionItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Comment = odataObject.Comment;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.HideOnPos = odataObject.HideOnPos;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.HeavyLight = odataObject.HeavyLight;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemId = odataObject.ItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemPartType = odataObject.ItemPartType;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Name = odataObject.Name;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.OriginalQuantity = (odataObject.OriginalQuantity) ? parseFloat(odataObject.OriginalQuantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Price = (odataObject.Price) ? parseFloat(odataObject.Price) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ReducePrice = odataObject.ReducePrice;
              
      }
  }

  /**
   * LshProductModifierGroup entity class.
   */
  export class LshProductModifierGroupExtensionClass implements ILshProductModifierGroup {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public AdditionalModifiersNeeded: boolean;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Caption: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ModifierGroupId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MaxQuantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MinQuantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Modifiers: 
            ILshProductModifier[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.AdditionalModifiersNeeded = odataObject.AdditionalModifiersNeeded;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Caption = odataObject.Caption;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ModifierGroupId = odataObject.ModifierGroupId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MaxQuantity = (odataObject.MaxQuantity) ? parseFloat(odataObject.MaxQuantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MinQuantity = (odataObject.MinQuantity) ? parseFloat(odataObject.MinQuantity) : 0;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Modifiers = undefined;
        if (odataObject.Modifiers) {
          this.Modifiers = [];
          for (var i = 0; i < odataObject.Modifiers.length; i++) {
            if (odataObject.Modifiers[i]) {
        if (odataObject.Modifiers[i]['@odata.type']) {
          var className: string = odataObject.Modifiers[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Modifiers[i] = new EntityClasses[className](odataObject.Modifiers[i])
          }
        } else {
          this.Modifiers[i] = new LshProductModifierExtensionClass(odataObject.Modifiers[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.Modifiers[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * LshProductModifier entity class.
   */
  export class LshProductModifierExtensionClass implements ILshProductModifier {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BomVersionItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Comment: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemPartType: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Name: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Price: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BomVersionItemId = odataObject.BomVersionItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Comment = odataObject.Comment;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemId = odataObject.ItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemPartType = odataObject.ItemPartType;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Name = odataObject.Name;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Price = (odataObject.Price) ? parseFloat(odataObject.Price) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;
              
      }
  }

  /**
   * LsPredefinedCommentGroup entity class.
   */
  export class LsPredefinedCommentGroupExtensionClass implements ILsPredefinedCommentGroup {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Description: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public GroupId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MaxSelection: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MinSelection: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PredefinedComments: 
            ILsPredefinedComment[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Description = odataObject.Description;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.GroupId = odataObject.GroupId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemId = odataObject.ItemId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MaxSelection = odataObject.MaxSelection;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MinSelection = odataObject.MinSelection;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PredefinedComments = undefined;
        if (odataObject.PredefinedComments) {
          this.PredefinedComments = [];
          for (var i = 0; i < odataObject.PredefinedComments.length; i++) {
            if (odataObject.PredefinedComments[i]) {
        if (odataObject.PredefinedComments[i]['@odata.type']) {
          var className: string = odataObject.PredefinedComments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PredefinedComments[i] = new EntityClasses[className](odataObject.PredefinedComments[i])
          }
        } else {
          this.PredefinedComments[i] = new LsPredefinedCommentExtensionClass(odataObject.PredefinedComments[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.PredefinedComments[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * LsPredefinedComment entity class.
   */
  export class LsPredefinedCommentExtensionClass implements ILsPredefinedComment {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Comment: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Comment = odataObject.Comment;
              
      }
  }

  /**
   * LsDeliveryMode entity class.
   */
  export class LsDeliveryModeExtensionClass implements ILsDeliveryMode {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DeliveryModeId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DeliveryModeDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DeliveryModeProfileId: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public QsrDestinationId: number;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DeliveryModeId = odataObject.DeliveryModeId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DeliveryModeDescription = odataObject.DeliveryModeDescription;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DeliveryModeProfileId = odataObject.DeliveryModeProfileId;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.QsrDestinationId = odataObject.QsrDestinationId;
              
      }
  }
