//import { ReactElement } from 'enzyme/node_modules/@types/react';
import * as React from 'react';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { makeAlertFromLsErrorArray } from '../../../../modules/lsfirst-buybox/components/LsFirstAlert';
import { LsFirstBuyboxContext } from '../../../../modules/lsfirst-buybox/Context/LsFirstBuyboxContext';
import { ILsFirstBuyboxCallbacks } from '../../../../modules/lsfirst-buybox/ILsFirstBuyboxCallbacks';
import { ILsError } from '../../../../modules/lsfirst-buybox/State/LsFirstBuyboxState';

import { ILshProductModifier, ILshProductModifierGroup } from '../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { DealLine } from '../DataModel/Deals/DealLines/DealLine';

interface IObjectWithProductModifierGroupsProp {
    ProductModifierGroups?: ILshProductModifierGroup[];
}

export class ProductModifierGroups {
    public static GetProductModifierGroups(
        objectWithProductModifierGroupsArray: IObjectWithProductModifierGroupsProp,
        callbacks: ILsFirstBuyboxCallbacks,
        dealLine?: DealLine
    ): React.ReactNode | undefined {
        const productModifierGroups = objectWithProductModifierGroupsArray.ProductModifierGroups || [];
        if (productModifierGroups.length === 0) {
            return undefined;
        } else {
            var productModifierGroupsToElementConverter = new ProductModifierGroups();
            var productModifierGroupsAsElement = productModifierGroupsToElementConverter.CreateReactElement(
                productModifierGroups,
                callbacks,
                dealLine
            );

            return productModifierGroupsAsElement;
        }
    }

    private RenderProductModifierErrorsForId(id: string | undefined, errors: ILsError[]): JSX.Element | null {
        if (!id || errors.length === 0) {
            return null;
        }
        const errorsToShow = errors.filter(error => error.modifierGroupId === id);
        if (errorsToShow.length === 0) {
            return null;
        }
        return makeAlertFromLsErrorArray(errorsToShow, 'There are some issues here', 'Errors for Product Modifier Groups');
    }

    private CreateReactElement(
        productModifierGroups: ILshProductModifierGroup[],
        callbacks: ILsFirstBuyboxCallbacks,
        dealLine?: DealLine
    ): React.ReactElement {
        const productModifierGroupsAsElement = (
            <LsFirstBuyboxContext.Consumer>
                {({ lsErrorState }) => {
                    const thisProductErrors = lsErrorState ?? [];
                    const productModiferGroupsErrors = thisProductErrors.filter(
                        lsError => lsError.errorLocation === 'ProductModiferGroups'
                    );
                    return (
                        <div className='ProductModifierGroups-Container'>
                            <h2 className='ProductModifierGroups-Header'>Extras</h2>
                            <br></br>
                            {productModifierGroups.map((productModifierGroup, index) => (
                                <div className='ProductModifierGroups-TableContainer' key={productModifierGroup.ModifierGroupId}>
                                    <h3>
                                        {index + 1}. {productModifierGroup.Caption}
                                    </h3>

                                    {productModifierGroup.MaxQuantity > 0 ? (
                                        <h5>{`${this.GetAllProductModifiersInAGroupAsSum(productModifierGroup)} of ${
                                            productModifierGroup.MaxQuantity
                                        } allowed`}</h5>
                                    ) : null}

                                    {productModifierGroup.MinQuantity > 0 &&
                                    this.GetAllProductModifiersInAGroupAsSum(productModifierGroup) < productModifierGroup.MinQuantity ? (
                                        <h5 className='productModifierBuyBoxMandatorySelection'>
                                            {`A selection of at least ${productModifierGroup.MinQuantity} is required.`}
                                        </h5>
                                    ) : (
                                        /* prevent page from jumping around when the text disappears */
                                        <h5 className='productModifierBuyBoxMandatorySelection'> </h5>
                                    )}
                                    <br></br>
                                    {this.RenderProductModifierErrorsForId(
                                        productModifierGroup.ModifierGroupId,
                                        productModiferGroupsErrors
                                    )}
                                    <table className='table pmg-table'>
                                        <thead className='pmg-thead'>
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productModifierGroup.Modifiers?.map(productModifier => (
                                                <tr key={productModifier.ItemId} className='pmg-tr'>
                                                    <td className='pmg-td'>{productModifier.Name}</td>
                                                    <td className='pmg-td'>${productModifier.Price}</td>
                                                    <td>
                                                        <div className='pmg-quantityDisplay'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-primary pmg-qtybtn'
                                                                disabled={this.ShouldDisableDecrease(productModifier)}
                                                                onClick={() =>
                                                                    this.UpdateQuantity(
                                                                        productModifier,
                                                                        productModifierGroup,
                                                                        -1,
                                                                        callbacks,
                                                                        dealLine
                                                                    )
                                                                }
                                                            >
                                                                -
                                                            </button>
                                                            <span>{productModifier.Quantity}</span>

                                                            <button
                                                                type='button'
                                                                className='btn btn-primary pmg-qtybtn'
                                                                disabled={this.ShouldDisableIncrease(productModifierGroup)}
                                                                onClick={() => {
                                                                    this.UpdateQuantity(
                                                                        productModifier,
                                                                        productModifierGroup,
                                                                        1,
                                                                        callbacks,
                                                                        dealLine
                                                                    );
                                                                    callbacks.removeErrorsOfCategory('ProductModiferGroups');
                                                                }}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <br></br>
                                </div>
                            ))}
                        </div>
                    );
                }}
            </LsFirstBuyboxContext.Consumer>
        );

        return productModifierGroupsAsElement;
    }

    private GetAllProductModifiersInAGroupAsSum(productModifierGroup: ILshProductModifierGroup): number {
        if (ObjectExtensions.isNullOrUndefined(productModifierGroup.Modifiers)) {
            return 0;
        }

        const sum: number = productModifierGroup.Modifiers.map(productModifier => productModifier.Quantity).reduce(
            (previousValue, currentValue) => {
                return previousValue + currentValue;
            }
        );
        return sum;
    }

    private ShouldDisableIncrease(productModifierGroup: ILshProductModifierGroup): boolean {
        if (productModifierGroup.MaxQuantity === 0) {
            return false;
        }
        return this.GetAllProductModifiersInAGroupAsSum(productModifierGroup) >= productModifierGroup.MaxQuantity;
    }

    private ShouldDisableDecrease(productModifier: ILshProductModifier): boolean {
        // We want the user to be able to reduce the modifier back down to zero, so that they are not stuck with their first selection.
        return productModifier.Quantity === 0;
    }

    private UpdateQuantity(
        productModifier: ILshProductModifier,
        productModifierGroup: ILshProductModifierGroup,
        change: number,
        callbacks: ILsFirstBuyboxCallbacks,
        dealLine?: DealLine
    ): void {
        const newQuantity = productModifier.Quantity + change;
        const canUpdate: boolean =
            newQuantity >= 0 && (productModifierGroup.MaxQuantity === 0 || newQuantity <= productModifierGroup.MaxQuantity);
        if (canUpdate) {
            const temporaryProductModifier = { ...productModifier };
            temporaryProductModifier.Quantity = newQuantity;

            callbacks.updateRecipeOrDealLineProductModifierModifications(temporaryProductModifier, productModifierGroup, dealLine);
            callbacks.updateProductPriceDueToModifications(productModifier.Price * change);
        }
    }
}
