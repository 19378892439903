import * as React from 'react';
import { Alert } from 'reactstrap';

interface ILSFirstAlertProps {
    color: string;
    ariaLabel?: string;
    alertHeader?: string;
    children?: React.ReactNode;
}

interface ILSFirstAlertListProps {
    className?: string;
    children?: React.ReactNode;
}

export function LSFirstAlert({ ariaLabel, alertHeader, children, color = 'danger' }: ILSFirstAlertProps): JSX.Element {
    return (
        <Alert isOpen={true} color={color} assertive aria-label={ariaLabel}>
            <div className='lsfirst-alert-header' aria-hidden='true'>
                <span className='lsfirst-exclamation-triangle' />
                <span>{alertHeader}</span>
            </div>
            {children}
        </Alert>
    );
}

export function LSFirstAlertList({ className, children }: ILSFirstAlertListProps): JSX.Element {
    return <ul className={className}>{children}</ul>;
}

export function LSFirstAlertListItem({ className, children, ...props }: ILSFirstAlertListProps): JSX.Element {
    return (
        <li className={className} {...props}>
            {children}
        </li>
    );
}
