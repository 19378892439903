//import { ReactElement } from 'enzyme/node_modules/@types/react';
import * as React from 'react';

import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';

import { ILsFirstBuyboxCallbacks } from '../../../../modules/lsfirst-buybox/ILsFirstBuyboxCallbacks';
import { ILshDealLine } from '../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';

export class DealModifierGroups {
    public static GetDealModifierGroups(
        dealLine: ILshDealLine,
        selectedDealModifier: string,
        callbacks: ILsFirstBuyboxCallbacks
    ): React.ReactNode | undefined {
        if (ObjectExtensions.isNullOrUndefined(dealLine.DealModifierGroups) || dealLine.DealModifierGroups.length === 0) {
            return undefined;
        } else {
            var dealModifierGroupsToElementConverter = new DealModifierGroups();
            var productModifierGroupsAsElement = dealModifierGroupsToElementConverter.CreateReactElement(
                dealLine,
                selectedDealModifier,
                callbacks
            );

            return productModifierGroupsAsElement;
        }
    }

    private CreateReactElement(
        dealLine: ILshDealLine,
        selectedDealModifier: string,
        callbacks: ILsFirstBuyboxCallbacks
    ): React.ReactElement {
        const dealModifierGroups = dealLine.DealModifierGroups || [];
        const defaultDealModifierGroupCaption = dealModifierGroups.length === 0 ? undefined : dealModifierGroups[0].Caption;
        const defaultSelectionNode = (
            <label htmlFor={dealLine.ItemId}>
                <input
                    type='radio'
                    id={dealLine.ItemId}
                    name={`${dealLine.ProductId}`}
                    value={dealLine.ItemId}
                    checked={dealLine.ItemId === selectedDealModifier}
                    onChange={e => callbacks.updateSelectedDealLineModifier(dealLine.ItemId, dealLine.ItemId)}
                />
                <span>{dealLine.Name}</span>
            </label>
        );
        const productModifierGroupsAsElement = (
            <div>
                <h3>{defaultDealModifierGroupCaption}</h3>
                <br></br>

                {dealModifierGroups.map((dealModifierGroup, index) => (
                    <div className='dealModifierGroups-radio-container' key={dealModifierGroup.ModifierGroupId}>
                        {index === 0 ? defaultSelectionNode : null}
                        {index > 0 ? (
                            <>
                                <h3>{dealModifierGroup.Caption}</h3>
                                <br></br>
                            </>
                        ) : null}
                        {dealModifierGroup.DealModifiers?.map(dealModifier => {
                            return (
                                <label key={dealModifier.ModifierItemId} htmlFor={dealModifier.ModifierItemId}>
                                    <input
                                        type='radio'
                                        id={dealModifier.ModifierItemId}
                                        name={`${dealLine.ProductId}`}
                                        value={dealModifier.ModifierItemId}
                                        checked={dealModifier.ModifierItemId === selectedDealModifier}
                                        onChange={e =>
                                            callbacks.updateSelectedDealLineModifier(dealLine.ItemId, dealModifier.ModifierItemId)
                                        }
                                    />
                                    <span>{dealModifier.ItemName}</span>
                                </label>
                            );
                        })}
                        <br></br>
                    </div>
                ))}
            </div>
        );
        return productModifierGroupsAsElement;
    }
}
