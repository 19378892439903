import {
    ILshDealModifier,
    ILshIngredient,
    ILshProductModifierGroup
} from '../../../../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { DataModelBuilder } from '../../../../../../Domain/Common/DataModelBuilder/DataModelBuilder';
import { DealLine } from '../../DealLines/DealLine';
export class DealModifier implements ILshDealModifier {
    public Price!: number;
    public BomVersionItemId?: string;
    public Ingredients?: ILshIngredient[];
    public ItemName?: string;
    public ModifierItemId?: string;
    public ProductModifierGroups?: ILshProductModifierGroup[];

    public static GetDealModifierById(dealLine: DealLine, selectedDealModifierId: string): DealModifier | null {
        if (dealLine.ItemId === selectedDealModifierId) {
            // no dealModifier selected, return null
            return null;
        }
        for (const dealModifierGroup of dealLine.DealModifierGroups ?? []) {
            for (const dealModifier of dealModifierGroup.DealModifiers ?? []) {
                if (dealModifier.ModifierItemId === selectedDealModifierId) {
                    return dealModifier;
                }
            }
        }
        return null;
    }
}

export class DealModifierBuilder extends DataModelBuilder<DealModifier> {
    constructor() {
        super(DealModifier);
    }
    public Copy(dealModifier: DealModifier): DataModelBuilder<DealModifier> {
        if (dealModifier.BomVersionItemId) {
            this.BomVersionItemId(dealModifier.BomVersionItemId);
        }
        if (dealModifier.Ingredients) {
            this.Ingredients(dealModifier.Ingredients);
        }
        if (dealModifier.ItemName) {
            this.ItemName(dealModifier.ItemName);
        }
        if (dealModifier.ModifierItemId) {
            this.ModifierItemId(dealModifier.ModifierItemId);
        }
        if (dealModifier.ProductModifierGroups) {
            this.ProductModifierGroups(dealModifier.ProductModifierGroups);
        }

        this.Price(dealModifier.Price);
        return this;
    }

    Price(Price: number): DealModifierBuilder {
        this.object.Price = Price;
        return this;
    }

    ProductModifierGroups(ProductModifierGroups: ILshProductModifierGroup[]): DealModifierBuilder {
        this.object.ProductModifierGroups = ProductModifierGroups;
        return this;
    }

    ModifierItemId(ModifierItemId: string): DealModifierBuilder {
        this.object.ModifierItemId = ModifierItemId;
        return this;
    }
    ItemName(ItemName: string): DealModifierBuilder {
        this.object.ItemName = ItemName;
        return this;
    }
    Ingredients(Ingredients: ILshIngredient[]): DealModifierBuilder {
        this.object.Ingredients = Ingredients;
        return this;
    }
    BomVersionItemId(BomVersionItemId: string): DealModifierBuilder {
        this.object.BomVersionItemId = BomVersionItemId;
        return this;
    }
}
