export abstract class GenericClassBase<Type> {
    protected object: Type;

    protected CreateObject(type: new () => Type): Type {
        return new type();
    }

    constructor(type: any) {
        this.object = this.CreateObject(type);
    }

    public Get(): Type {
        return this.object;
    }
}
